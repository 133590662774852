var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "4", "mb-2": "" } },
            [
              _c("pga-text-area", {
                attrs: { label: "Notes", readonly: "" },
                model: {
                  value: _vm.notes,
                  callback: function ($$v) {
                    _vm.notes = $$v
                  },
                  expression: "notes",
                },
              }),
            ],
            1
          ),
          _c("v-col", { staticClass: "comp", attrs: { cols: "12", sm: "8" } }, [
            _c(
              "div",
              {
                staticClass:
                  "v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-select v-text-field__slot",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "label",
                      {
                        staticClass: "v-label v-label--active theme--light",
                        staticStyle: {
                          left: "0px",
                          right: "auto",
                          position: "absolute",
                        },
                      },
                      [_vm._v(" Documents ")]
                    ),
                    _c("br"),
                    _c(
                      "v-layout",
                      _vm._l(_vm.blobs, function (blob, i) {
                        return _c("v-chip", { key: i, attrs: { small: "" } }, [
                          _c("a", { attrs: { href: blob.UriSASToken } }, [
                            _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.getFileName(blob.Name)) + " "
                              ),
                            ]),
                          ]),
                        ])
                      }),
                      1
                    ),
                    _vm.blobs.length < 1
                      ? _c("div", [
                          _c("em", [_vm._v(" There is no one document ")]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }