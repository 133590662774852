var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { elevation: "10" } },
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditOfferEvaluationCustomerOrder" } },
            [
              _vm.dealId !== ""
                ? _c("pga-general-button", {
                    attrs: {
                      text: "Select Deal",
                      icon: "mdi-check-circle",
                      "icon-color": "success",
                    },
                    on: { click: _vm.SelectDeal },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("pga-cancel-button", { on: { click: _vm.undo } }),
        ],
        1
      ),
      _c("pga-hub-spot-deal-selector", {
        on: {
          "selected-deal": function ($event) {
            _vm.dealId = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }