import { render, staticRenderFns } from "./PgaOfferEvaluationInnerProjectTable.vue?vue&type=template&id=536a7791&"
import script from "./PgaOfferEvaluationInnerProjectTable.vue?vue&type=script&lang=ts&"
export * from "./PgaOfferEvaluationInnerProjectTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('536a7791')) {
      api.createRecord('536a7791', component.options)
    } else {
      api.reload('536a7791', component.options)
    }
    module.hot.accept("./PgaOfferEvaluationInnerProjectTable.vue?vue&type=template&id=536a7791&", function () {
      api.rerender('536a7791', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/feature/Offerte/OfferEvaluation/components/tables/PgaOfferEvaluationInnerProjectTable.vue"
export default component.exports