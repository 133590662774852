var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    [
      _c("v-expansion-panel-header", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var open = ref.open
              return [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text--secondary", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-fade-transition",
                          { attrs: { "leave-absolute": "" } },
                          [
                            open
                              ? _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "4", md: "3" },
                                      },
                                      [_c("span", [_vm._v(" Total Costs ")])]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { dense: "" },
                                  },
                                  [
                                    _c("h4", [_vm._v(" Total Costs ")]),
                                    _c("br"),
                                    _c("h4", [_vm._v(" Presale: ")]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.FormatMoney(_vm.presale || 0)
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("br"),
                                    _c("h4", [_vm._v(" Postsale: ")]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.FormatMoney(_vm.postsale || 0)
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-expansion-panel-content",
        [
          _c("pga-offer-evaluation-total-costs-presale-create", {
            on: {
              presale: function ($event) {
                _vm.presale = $event
              },
            },
          }),
          _c("pga-offer-evaluation-total-costs-postsale-create", {
            on: {
              postsale: function ($event) {
                _vm.postsale = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }