import { render, staticRenderFns } from "./PgaOrderRequirementsReviewEdit.vue?vue&type=template&id=1edca480&"
import script from "./PgaOrderRequirementsReviewEdit.vue?vue&type=script&lang=ts&"
export * from "./PgaOrderRequirementsReviewEdit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1edca480')) {
      api.createRecord('1edca480', component.options)
    } else {
      api.reload('1edca480', component.options)
    }
    module.hot.accept("./PgaOrderRequirementsReviewEdit.vue?vue&type=template&id=1edca480&", function () {
      api.rerender('1edca480', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/feature/Offerte/business-startups/customer-orders/components/edit/sections/PgaOrderRequirementsReviewEdit.vue"
export default component.exports