var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "10" } },
        [
          _c("pga-text-field", {
            attrs: { label: "Other Customer Referral" },
            model: {
              value: _vm.otherCustomerReferral,
              callback: function ($$v) {
                _vm.otherCustomerReferral = $$v
              },
              expression: "otherCustomerReferral",
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "2" } },
        [
          _vm.index > 0
            ? _c("pga-general-button", {
                attrs: {
                  absolute: "",
                  dark: "",
                  "x-small": "",
                  fab: "",
                  icon: "mdi-minus",
                },
                on: { click: _vm.removeOtherCustomerReferral },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }