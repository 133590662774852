var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    [
      _c(
        "v-stepper",
        {
          attrs: { vertical: _vm.vertical },
          model: {
            value: _vm.e1,
            callback: function ($$v) {
              _vm.e1 = $$v
            },
            expression: "e1",
          },
        },
        [
          _c(
            "v-stepper-step",
            {
              attrs: {
                editable: _vm.editable,
                complete: _vm.e1 > 1,
                step: "1",
              },
            },
            [_vm._v("Intestazione")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "1" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12" },
                [_c("pga-valutazione-offerta-header-create")],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              attrs: {
                editable: _vm.editable,
                complete: _vm.e1 > 2,
                step: "2",
              },
            },
            [_vm._v("Progetto A Corpo")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "2" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12" },
                [_c("pga-valutazione-offerta-progetto-a-corpo-create")],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              attrs: {
                editable: _vm.editable,
                complete: _vm.e1 > 3,
                step: "3",
              },
            },
            [_vm._v("Risorse Umane")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12" },
                [
                  _c("pga-valutazione-offerta-subtotali-risorse-umane", {
                    model: {
                      value: _vm.PgaValutazioneOffertaSubtotaliRisorseUmane,
                      callback: function ($$v) {
                        _vm.PgaValutazioneOffertaSubtotaliRisorseUmane = $$v
                      },
                      expression: "PgaValutazioneOffertaSubtotaliRisorseUmane",
                    },
                  }),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.PgaValutazioneOffertaRisorseUmaneCreate,
                          function (input, idx) {
                            return _c(
                              "pga-valutazione-offerta-risorse-umane-create",
                              {
                                key: idx,
                                attrs: { index: idx },
                                model: {
                                  value:
                                    _vm.PgaValutazioneOffertaRisorseUmaneCreate[
                                      idx
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.PgaValutazioneOffertaRisorseUmaneCreate,
                                      idx,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "PgaValutazioneOffertaRisorseUmaneCreate[idx]",
                                },
                              }
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            absolute: "",
                            dark: "",
                            fab: "",
                            bottom: "",
                            right: "",
                            color: "pink",
                          },
                          on: { click: _vm.addRisorsa },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              attrs: {
                editable: _vm.editable,
                complete: _vm.e1 > 4,
                step: "4",
              },
            },
            [_vm._v("Altre Componenti")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12" },
                [
                  _c("pga-valutazione-offerta-subtotali-altre-componenti", {
                    model: {
                      value: _vm.PgaValutazioneOffertaSubtotaliAltreComponenti,
                      callback: function ($$v) {
                        _vm.PgaValutazioneOffertaSubtotaliAltreComponenti = $$v
                      },
                      expression:
                        "PgaValutazioneOffertaSubtotaliAltreComponenti",
                    },
                  }),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.PgaValutazioneOffertaAltreComponentiCreate,
                          function (input, idx) {
                            return _c(
                              "pga-valutazione-offerta-altre-componenti-create",
                              {
                                key: idx,
                                attrs: { index: idx },
                                model: {
                                  value:
                                    _vm
                                      .PgaValutazioneOffertaAltreComponentiCreate[
                                      idx
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.PgaValutazioneOffertaAltreComponentiCreate,
                                      idx,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "PgaValutazioneOffertaAltreComponentiCreate[idx]",
                                },
                              }
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            absolute: "",
                            dark: "",
                            fab: "",
                            bottom: "",
                            right: "",
                            color: "pink",
                          },
                          on: { click: _vm.addAltreComponenti },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              attrs: {
                editable: _vm.editable,
                complete: _vm.e1 > 5,
                step: "5",
              },
            },
            [_vm._v("Costi Aggiuntivi Presale")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "5" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12" },
                [
                  _c("pga-valutazione-offerta-subtotali-aggiuntivi-presale", {
                    model: {
                      value:
                        _vm.PgaValutazioneOffertaSubtotaliAggiuntiviPresale,
                      callback: function ($$v) {
                        _vm.PgaValutazioneOffertaSubtotaliAggiuntiviPresale =
                          $$v
                      },
                      expression:
                        "PgaValutazioneOffertaSubtotaliAggiuntiviPresale",
                    },
                  }),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.PgaValutazioneOffertaAggiuntiviPresaleCreate,
                          function (input, idx) {
                            return _c(
                              "pga-valutazione-offerta-aggiuntivi-presale-create",
                              {
                                key: idx,
                                attrs: { index: idx },
                                model: {
                                  value:
                                    _vm
                                      .PgaValutazioneOffertaAggiuntiviPresaleCreate[
                                      idx
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.PgaValutazioneOffertaAggiuntiviPresaleCreate,
                                      idx,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "PgaValutazioneOffertaAggiuntiviPresaleCreate[idx]",
                                },
                              }
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            absolute: "",
                            dark: "",
                            fab: "",
                            bottom: "",
                            right: "",
                            color: "pink",
                          },
                          on: { click: _vm.addAggiuntiviPresale },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              attrs: {
                editable: _vm.editable,
                complete: _vm.e1 > 6,
                step: "6",
              },
            },
            [_vm._v("Altri Costi Presale")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12" },
                [
                  _c("pga-valutazione-offerta-subtotali-altri-presale", {
                    model: {
                      value: _vm.PgaValutazioneOffertaSubtotaliAltriPresale,
                      callback: function ($$v) {
                        _vm.PgaValutazioneOffertaSubtotaliAltriPresale = $$v
                      },
                      expression: "PgaValutazioneOffertaSubtotaliAltriPresale",
                    },
                  }),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.PgaValutazioneOffertaAltriPresaleCreate,
                          function (input, idx) {
                            return _c(
                              "pga-valutazione-offerta-altri-presale-create",
                              {
                                key: idx,
                                attrs: { index: idx },
                                model: {
                                  value:
                                    _vm.PgaValutazioneOffertaAltriPresaleCreate[
                                      idx
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.PgaValutazioneOffertaAltriPresaleCreate,
                                      idx,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "PgaValutazioneOffertaAltriPresaleCreate[idx]",
                                },
                              }
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            absolute: "",
                            dark: "",
                            fab: "",
                            bottom: "",
                            right: "",
                            color: "pink",
                          },
                          on: { click: _vm.addAltriPresale },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              attrs: {
                editable: _vm.editable,
                complete: _vm.e1 > 7,
                step: "7",
              },
            },
            [_vm._v("Altri Costi Postsale")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "7" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12" },
                [
                  _c("pga-valutazione-offerta-subtotali-postsale", {
                    model: {
                      value: _vm.PgaValutazioneOffertaSubtotaliPostsale,
                      callback: function ($$v) {
                        _vm.PgaValutazioneOffertaSubtotaliPostsale = $$v
                      },
                      expression: "PgaValutazioneOffertaSubtotaliPostsale",
                    },
                  }),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.PgaValutazioneOffertaPostsaleCreate,
                          function (input, idx) {
                            return _c(
                              "pga-valutazione-offerta-postsale-create",
                              {
                                key: idx,
                                attrs: { index: idx },
                                model: {
                                  value:
                                    _vm.PgaValutazioneOffertaPostsaleCreate[
                                      idx
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.PgaValutazioneOffertaPostsaleCreate,
                                      idx,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "PgaValutazioneOffertaPostsaleCreate[idx]",
                                },
                              }
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            absolute: "",
                            dark: "",
                            fab: "",
                            bottom: "",
                            right: "",
                            color: "pink",
                          },
                          on: { click: _vm.addPostsale },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("v-divider"),
      _c("br"),
      _c(
        "v-card",
        { staticClass: "mb-12" },
        [
          _c("pga-valutazione-offerta-totali-offerta-create"),
          _c("pga-valutazione-offerta-totali-offerta-valutazione"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }