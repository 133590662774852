var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditBusinessStartupCustomerOrder" } },
            [
              !!_vm.customerOrderId
                ? _c("pga-general-button", {
                    attrs: {
                      text: "Save",
                      icon: "mdi-check-circle",
                      "icon-color": "success",
                    },
                    on: { click: _vm.Save },
                  })
                : _c("pga-general-button", {
                    attrs: {
                      text: "Create",
                      icon: "mdi-check-circle",
                      "icon-color": "success",
                    },
                    on: { click: _vm.createOrderRequirementsReview },
                  }),
            ],
            1
          ),
          _c("pga-cancel-button", { on: { click: _vm.undo } }),
        ],
        1
      ),
      _c(
        "v-form",
        [
          _c("v-card-title", [
            _vm._v(" Create Business Startup for External Project "),
          ]),
          _c(
            "v-expansion-panels",
            {
              attrs: { disabled: _vm.customerOrderId === null, multiple: "" },
              model: {
                value: _vm.panelsOpened,
                callback: function ($$v) {
                  _vm.panelsOpened = $$v
                },
                expression: "panelsOpened",
              },
            },
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", {
                    attrs: {
                      color:
                        _vm.hasErrorsOrderRequirementsReview && !_vm.open
                          ? "error"
                          : "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var open = ref.open
                          return [_vm._v(" Order Requirements Review ")]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [_c("pga-order-requirements-review-create")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }