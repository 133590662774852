var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("pga-text-area", {
                attrs: { label: "Description", readonly: "" },
                model: {
                  value: _vm.description,
                  callback: function ($$v) {
                    _vm.description = $$v
                  },
                  expression: "description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "2" } },
            [
              _c("pga-number-field", {
                attrs: {
                  label: "Days Men Expected",
                  readonly: "",
                  decimal: "",
                },
                model: {
                  value: _vm.daysMenExpected,
                  callback: function ($$v) {
                    _vm.daysMenExpected = $$v
                  },
                  expression: "daysMenExpected",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-currency-field", {
                attrs: { label: "Expected Cost", readonly: "" },
                model: {
                  value: _vm.expectedCost,
                  callback: function ($$v) {
                    _vm.expectedCost = $$v
                  },
                  expression: "expectedCost",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "1" } },
            [
              _c("pga-switch", {
                attrs: { title: "Market", inset: "", disabled: "" },
                model: {
                  value: _vm.market,
                  callback: function ($$v) {
                    _vm.market = $$v
                  },
                  expression: "market",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "1" } },
            [
              _c("pga-switch", {
                attrs: { title: "Internal Use", inset: "", disabled: "" },
                model: {
                  value: _vm.internalUse,
                  callback: function ($$v) {
                    _vm.internalUse = $$v
                  },
                  expression: "internalUse",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-text-field", {
                attrs: { label: "ACOM", readonly: "" },
                model: {
                  value: _vm.acom,
                  callback: function ($$v) {
                    _vm.acom = $$v
                  },
                  expression: "acom",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }