var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditOfferEvaluationInternalProject" } },
            [
              _c("pga-general-button", {
                attrs: {
                  text: "Save as Draft",
                  icon: "mdi-check-circle",
                  "icon-color": "warning",
                },
                on: { click: _vm.SaveAsDraft },
              }),
              _c("pga-general-button", {
                attrs: {
                  text: "Save",
                  icon: "mdi-check-circle",
                  "icon-color": "success",
                },
                on: { click: _vm.Save },
              }),
            ],
            1
          ),
          _c("pga-cancel-button", { on: { click: _vm.undo } }),
        ],
        1
      ),
      _c(
        "v-form",
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Edit Internal Project ")]),
              _c(
                "v-card-text",
                [_c("pga-offer-evaluation-header-internal-project-edit")],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c("v-divider"),
          _c("br"),
          _c(
            "v-expansion-panels",
            {
              attrs: { multiple: "" },
              model: {
                value: _vm.panelsOpened,
                callback: function ($$v) {
                  _vm.panelsOpened = $$v
                },
                expression: "panelsOpened",
              },
            },
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", {
                    attrs: {
                      color:
                        _vm.hasErrorDetailsInternalProject && !_vm.open
                          ? "error"
                          : "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var open = ref.open
                          return [_vm._v(" Details Internal Project ")]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "pga-offer-evaluation-details-internal-project-create"
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", {
                    attrs: {
                      color:
                        _vm.hasErrorsAttachments && !_vm.open ? "error" : "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var open = ref.open
                          return [_vm._v(" Attachments ")]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [_c("pga-offer-evaluation-attachments-create")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }