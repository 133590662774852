var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    [
      _c("v-expansion-panel-header", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var open = ref.open
              return [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text--secondary", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-fade-transition",
                          { attrs: { "leave-absolute": "" } },
                          [
                            open
                              ? _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "4", md: "3" },
                                      },
                                      [
                                        _c("h3", [
                                          _vm._v(" Products Purchase "),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-row",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { dense: "" },
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "4", md: "3" },
                                      },
                                      [
                                        _c("h4", [_vm._v(" Tipology: ")]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.componentTipology || ""
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "4", md: "3" },
                                      },
                                      [
                                        _c("h4", [
                                          _vm._v(" Proposal Amount: "),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.FormatMoney(
                                                _vm.offerAmount || 0
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "4", md: "3" },
                                      },
                                      [
                                        _c("h4", [_vm._v(" Cost: ")]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.FormatMoney(
                                                _vm.expectedCost || 0
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "4", md: "3" },
                                      },
                                      [
                                        _c("h4", [_vm._v(" Margin: ")]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.expectedMarginPerc + "%" || 0
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-expansion-panel-content",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
                [
                  _c("pga-text-field", {
                    attrs: { label: "Business Lines", readonly: "" },
                    model: {
                      value: _vm.businessLine,
                      callback: function ($$v) {
                        _vm.businessLine = $$v
                      },
                      expression: "businessLine",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
                [
                  _c("pga-text-field", {
                    attrs: { label: "Tech Area", readonly: "" },
                    model: {
                      value: _vm.techArea,
                      callback: function ($$v) {
                        _vm.techArea = $$v
                      },
                      expression: "techArea",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "9", lg: "6" } },
                [
                  _c("pga-text-field", {
                    attrs: { label: "Supply Tipology", readonly: "" },
                    model: {
                      value: _vm.supplyTipology,
                      callback: function ($$v) {
                        _vm.supplyTipology = $$v
                      },
                      expression: "supplyTipology",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "3" } },
                [
                  _c("pga-text-field", {
                    attrs: { label: "Supplier Code", readonly: "" },
                    model: {
                      value: _vm.supplierCode,
                      callback: function ($$v) {
                        _vm.supplierCode = $$v
                      },
                      expression: "supplierCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "3" } },
                [
                  _c("pga-text-field", {
                    attrs: { label: "Supplier Business Name", readonly: "" },
                    model: {
                      value: _vm.supplierBusinessName,
                      callback: function ($$v) {
                        _vm.supplierBusinessName = $$v
                      },
                      expression: "supplierBusinessName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "3" } },
                [
                  _c("pga-text-field", {
                    attrs: { label: "Products Catalog", readonly: "" },
                    model: {
                      value: _vm.productCatalog,
                      callback: function ($$v) {
                        _vm.productCatalog = $$v
                      },
                      expression: "productCatalog",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "3" } },
                [
                  _c("pga-text-field", {
                    attrs: { label: "Description", readonly: "" },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "2" } },
                [
                  _c("pga-currency-field", {
                    attrs: { label: "Proposal Amount", readonly: "" },
                    model: {
                      value: _vm.offerAmount,
                      callback: function ($$v) {
                        _vm.offerAmount = $$v
                      },
                      expression: "offerAmount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "2" } },
                [
                  _c("pga-currency-field", {
                    attrs: { label: "Expected Cost", readonly: "" },
                    model: {
                      value: _vm.expectedCost,
                      callback: function ($$v) {
                        _vm.expectedCost = $$v
                      },
                      expression: "expectedCost",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "2" } },
                [
                  _c("pga-number-field", {
                    attrs: { label: "Expected Margin", readonly: "" },
                    model: {
                      value: _vm.expectedMargin,
                      callback: function ($$v) {
                        _vm.expectedMargin = $$v
                      },
                      expression: "expectedMargin",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "2" } },
                [
                  _c("pga-text-field", {
                    attrs: {
                      prefix: "%",
                      label: "Expected Margin %",
                      readonly: "",
                    },
                    model: {
                      value: _vm.expectedMarginPerc,
                      callback: function ($$v) {
                        _vm.expectedMarginPerc = $$v
                      },
                      expression: "expectedMarginPerc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }