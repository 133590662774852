var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    [
      _c("v-expansion-panel-header", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var open = ref.open
              return [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text--secondary", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-fade-transition",
                          { attrs: { "leave-absolute": "" } },
                          [
                            open
                              ? _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "4", md: "3" },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(" Expected Revenue "),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { dense: "" },
                                  },
                                  [
                                    _c("h4", [_vm._v(" Fee: ")]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.expectedFee || 0) +
                                          " % "
                                      ),
                                    ]),
                                    _c("br"),
                                    _c("h4", [_vm._v(" Expected Revenue: ")]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.FormatMoney(
                                              _vm.expectedRevenue || 0
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-expansion-panel-content",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("pga-currency-field", {
                    attrs: { label: "Expected Fee", dense: "", readonly: "" },
                    model: {
                      value: _vm.expectedFee,
                      callback: function ($$v) {
                        _vm.expectedFee = $$v
                      },
                      expression: "expectedFee",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("pga-currency-field", {
                    attrs: {
                      label: "Expected Revenue",
                      dense: "",
                      readonly: "",
                    },
                    model: {
                      value: _vm.expectedRevenue,
                      callback: function ($$v) {
                        _vm.expectedRevenue = $$v
                      },
                      expression: "expectedRevenue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("pga-currency-field", {
                    attrs: { label: "Expected Costs", dense: "", readonly: "" },
                    model: {
                      value: _vm.expectedCosts,
                      callback: function ($$v) {
                        _vm.expectedCosts = $$v
                      },
                      expression: "expectedCosts",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("pga-currency-field", {
                    attrs: {
                      label: "Expected Margins Revenue",
                      dense: "",
                      readonly: "",
                    },
                    model: {
                      value: _vm.expectedMarginsRevenue,
                      callback: function ($$v) {
                        _vm.expectedMarginsRevenue = $$v
                      },
                      expression: "expectedMarginsRevenue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("pga-text-field", {
                    attrs: {
                      label: "Expected Margins Revenue %",
                      prefix: "%",
                      dense: "",
                      readonly: "",
                    },
                    model: {
                      value: _vm.expectedMarginsRevenuePerc,
                      callback: function ($$v) {
                        _vm.expectedMarginsRevenuePerc = $$v
                      },
                      expression: "expectedMarginsRevenuePerc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }