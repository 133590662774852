var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-data-table", {
    attrs: {
      title: "Proposal Evaluation External Project Modules",
      headers: _vm.headers,
      data: _vm.offersEvalutaionCustomerOrderList,
      loading: _vm.isLoading,
      "show-expand": "",
    },
    on: { "row-clicked": _vm.details },
    scopedSlots: _vm._u([
      {
        key: "expand",
        fn: function (props) {
          return [
            props.row.item.Blobs.length > 0
              ? _c(
                  "td",
                  { attrs: { colspan: "12" } },
                  [
                    _c(
                      "v-row",
                      { attrs: { dense: "" } },
                      _vm._l(props.row.item.Blobs, function (blob, i) {
                        return _c(
                          "v-col",
                          { key: i, attrs: { cols: "12", sm: "3" } },
                          [
                            _c("v-chip", { attrs: { small: "" } }, [
                              _c("a", { attrs: { href: blob.UriSASToken } }, [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getFileName(blob.Name)) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _c("td", { attrs: { colspan: "12" } }, [
                  _c("em", [
                    _vm._v(
                      " There are no documents for External Project '" +
                        _vm._s(props.row.item.CustomerName) +
                        "'"
                    ),
                  ]),
                ]),
          ]
        },
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("pga-switch", {
              staticClass: "pa-0 pl-2",
              staticStyle: { position: "absolute" },
              attrs: { label: "Show Closed" },
              on: { change: _vm.getCustomerOrdersClosed },
              model: {
                value: _vm.showClosed,
                callback: function ($$v) {
                  _vm.showClosed = $$v
                },
                expression: "showClosed",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }