var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v("Totali Offerta"),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Importo Offerta",
                  prefix: "€",
                  type: "number",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.ImportoOffertaTotale,
                  callback: function ($$v) {
                    _vm.ImportoOffertaTotale = $$v
                  },
                  expression: "ImportoOffertaTotale",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Costo Atteso",
                  prefix: "€",
                  type: "number",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.CostoAttesoTotale,
                  callback: function ($$v) {
                    _vm.CostoAttesoTotale = $$v
                  },
                  expression: "CostoAttesoTotale",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Margine Atteso",
                  prefix: "€",
                  type: "number",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.MargineAttesoTotale,
                  callback: function ($$v) {
                    _vm.MargineAttesoTotale = $$v
                  },
                  expression: "MargineAttesoTotale",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Margine Atteso %",
                  prefix: "%",
                  type: "number",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.MargineAttesoPercTotale,
                  callback: function ($$v) {
                    _vm.MargineAttesoPercTotale = $$v
                  },
                  expression: "MargineAttesoPercTotale",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v("Sconto"),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: { label: "Sconto", prefix: "%", type: "number" },
                model: {
                  value: _vm.Sconto,
                  callback: function ($$v) {
                    _vm.Sconto = $$v
                  },
                  expression: "Sconto",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v("Totali Offerta Scontati"),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Importo Offerta",
                  prefix: "€",
                  type: "number",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.ImportoOffertaTotaleScontato,
                  callback: function ($$v) {
                    _vm.ImportoOffertaTotaleScontato = $$v
                  },
                  expression: "ImportoOffertaTotaleScontato",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "CostoAtteso",
                  prefix: "€",
                  type: "number",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.CostoAttesoTotaleScontato,
                  callback: function ($$v) {
                    _vm.CostoAttesoTotaleScontato = $$v
                  },
                  expression: "CostoAttesoTotaleScontato",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Margine Atteso",
                  prefix: "€",
                  type: "number",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.MargineAttesoTotaleScontato,
                  callback: function ($$v) {
                    _vm.MargineAttesoTotaleScontato = $$v
                  },
                  expression: "MargineAttesoTotaleScontato",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Margine Atteso %",
                  prefix: "%",
                  type: "number",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.MargineAttesoPercTotaleScontato,
                  callback: function ($$v) {
                    _vm.MargineAttesoPercTotaleScontato = $$v
                  },
                  expression: "MargineAttesoPercTotaleScontato",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }