var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-chip", { staticClass: "ma-2", attrs: { small: "" } }, [
                _vm._v(" Totals Proposal Evaluation "),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("pga-currency-field", {
                attrs: { label: "Proposal Amounts", dense: "", readonly: "" },
                model: {
                  value: _vm.offerAmounts,
                  callback: function ($$v) {
                    _vm.offerAmounts = $$v
                  },
                  expression: "offerAmounts",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("pga-currency-field", {
                attrs: { label: "Expected Costs", dense: "", readonly: "" },
                model: {
                  value: _vm.expectedCosts,
                  callback: function ($$v) {
                    _vm.expectedCosts = $$v
                  },
                  expression: "expectedCosts",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("pga-currency-field", {
                attrs: { label: "Expected Margins", dense: "", readonly: "" },
                model: {
                  value: _vm.expectedMargins,
                  callback: function ($$v) {
                    _vm.expectedMargins = $$v
                  },
                  expression: "expectedMargins",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("pga-text-field", {
                attrs: {
                  label: "Expected Margins %",
                  prefix: "%",
                  dense: "",
                  readonly: "",
                },
                model: {
                  value: _vm.expectedMarginsPerc,
                  callback: function ($$v) {
                    _vm.expectedMarginsPerc = $$v
                  },
                  expression: "expectedMarginsPerc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }