var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-data-table", {
    attrs: {
      title: "List HubSpot Deals",
      headers: _vm.headers,
      data: _vm.listHubSpotDeals,
      loading: _vm.isLoading,
      "item-key": "Id",
      "show-select": "",
      "single-select": "",
      "col-props": [
        "Amount",
        "Properties.CreateDate",
        "Properties.HsLastModifiedDate",
      ],
    },
    scopedSlots: _vm._u([
      {
        key: "column-Amount",
        fn: function (props) {
          return [
            _vm._v(
              " " +
                _vm._s(_vm.FormatMoney(props.row.item.Properties.Amount)) +
                " "
            ),
          ]
        },
      },
      {
        key: "column-Properties.CreateDate",
        fn: function (props) {
          return [
            _vm._v(
              " " +
                _vm._s(
                  _vm.getFormattedDate(props.row.item.Properties.CreateDate)
                ) +
                " "
            ),
          ]
        },
      },
      {
        key: "column-Properties.HsLastModifiedDate",
        fn: function (props) {
          return [
            _vm._v(
              " " +
                _vm._s(
                  _vm.getFormattedDate(
                    props.row.item.Properties.HsLastModifiedDate
                  )
                ) +
                " "
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedDeal,
      callback: function ($$v) {
        _vm.selectedDeal = $$v
      },
      expression: "selectedDeal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }