var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Imponibile Complessivo",
                  prefix: "€",
                  type: "number",
                },
                model: {
                  value: _vm.ImponibileComplessivo,
                  callback: function ($$v) {
                    _vm.ImponibileComplessivo = $$v
                  },
                  expression: "ImponibileComplessivo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }