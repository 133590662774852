import { render, staticRenderFns } from "./PgaOtherCustomerReferralCreate.vue?vue&type=template&id=b11c1b78&"
import script from "./PgaOtherCustomerReferralCreate.vue?vue&type=script&lang=ts&"
export * from "./PgaOtherCustomerReferralCreate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b11c1b78')) {
      api.createRecord('b11c1b78', component.options)
    } else {
      api.reload('b11c1b78', component.options)
    }
    module.hot.accept("./PgaOtherCustomerReferralCreate.vue?vue&type=template&id=b11c1b78&", function () {
      api.rerender('b11c1b78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/feature/Offerte/business-startups/customer-orders/components/create/sub-components/PgaOtherCustomerReferralCreate.vue"
export default component.exports