import { render, staticRenderFns } from "./PgaBusinessStartupInternalProjectDetails.vue?vue&type=template&id=040cdbac&"
import script from "./PgaBusinessStartupInternalProjectDetails.vue?vue&type=script&lang=ts&"
export * from "./PgaBusinessStartupInternalProjectDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VCardTitle,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VForm})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('040cdbac')) {
      api.createRecord('040cdbac', component.options)
    } else {
      api.reload('040cdbac', component.options)
    }
    module.hot.accept("./PgaBusinessStartupInternalProjectDetails.vue?vue&type=template&id=040cdbac&", function () {
      api.rerender('040cdbac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/feature/Offerte/business-startups/internal-projects/components/details/PgaBusinessStartupInternalProjectDetails.vue"
export default component.exports