var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { elevation: "10" } },
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            {
              attrs: { permission: "ReassignACOMOfferEvaluationCustomerOrder" },
            },
            [
              _c("pga-save-button", {
                attrs: {
                  text: "Reassign ACOM",
                  icon: "mdi-check-circle",
                  "icon-color": "success",
                },
                on: {
                  click: function ($event) {
                    _vm.showFirstDialog = true
                  },
                },
              }),
            ],
            1
          ),
          _c("pga-cancel-button", { on: { click: _vm.undo } }),
        ],
        1
      ),
      _c("pga-reassignment-acom"),
      _c("pga-dialog", {
        attrs: {
          title: "Notify Outcoming ACOM",
          "icon-title": "mdi-delete-alert",
          message: "Do you want notify outcoming ACOM?",
          color: "orange",
          "icon-button": "mdi-checkbox-marked-circle-outline",
        },
        on: {
          agree: function ($event) {
            return _vm.ResponseFirstDialog(true)
          },
          reject: function ($event) {
            return _vm.ResponseFirstDialog(false)
          },
        },
        model: {
          value: _vm.showFirstDialog,
          callback: function ($$v) {
            _vm.showFirstDialog = $$v
          },
          expression: "showFirstDialog",
        },
      }),
      _c("pga-dialog", {
        attrs: {
          title: "ACOM and BusinessLine associations",
          "icon-title": "mdi-delete-alert",
          message: _vm.warningMessage + "<br> Do you want continue?",
          color: "orange",
          "icon-button": "mdi-checkbox-marked-circle-outline",
        },
        on: {
          agree: _vm.ReassignACOM,
          reject: function ($event) {
            _vm.showSecondDialog = false
          },
        },
        model: {
          value: _vm.showSecondDialog,
          callback: function ($$v) {
            _vm.showSecondDialog = $$v
          },
          expression: "showSecondDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }