import { render, staticRenderFns } from "./PgaOfferEvaluationAdditionCostsPresaleSubtotalsDetails.vue?vue&type=template&id=1a106133&"
import script from "./PgaOfferEvaluationAdditionCostsPresaleSubtotalsDetails.vue?vue&type=script&lang=ts&"
export * from "./PgaOfferEvaluationAdditionCostsPresaleSubtotalsDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1a106133')) {
      api.createRecord('1a106133', component.options)
    } else {
      api.reload('1a106133', component.options)
    }
    module.hot.accept("./PgaOfferEvaluationAdditionCostsPresaleSubtotalsDetails.vue?vue&type=template&id=1a106133&", function () {
      api.rerender('1a106133', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/feature/Offerte/OfferEvaluation/components/details/sub-components/sub-totals/PgaOfferEvaluationAdditionCostsPresaleSubtotalsDetails.vue"
export default component.exports