var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Cliente",
                  readonly: "",
                  "hide-details": "auto",
                },
                model: {
                  value: _vm.cliente,
                  callback: function ($$v) {
                    _vm.cliente = $$v
                  },
                  expression: "cliente",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Protocollo Ordine/Contatto",
                  readonly: "",
                  "hide-details": "auto",
                },
                model: {
                  value: _vm.protocollo,
                  callback: function ($$v) {
                    _vm.protocollo = $$v
                  },
                  expression: "protocollo",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Tipologia Offerta",
                  readonly: "",
                  "hide-details": "auto",
                },
                model: {
                  value: _vm.tipologia,
                  callback: function ($$v) {
                    _vm.tipologia = $$v
                  },
                  expression: "tipologia",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Imponibile Complessivo",
                  readonly: "",
                  "hide-details": "auto",
                },
                model: {
                  value: _vm.imponibileComplessivo,
                  callback: function ($$v) {
                    _vm.imponibileComplessivo = $$v
                  },
                  expression: "imponibileComplessivo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-textarea", {
                attrs: {
                  label: "Descrizione Offerta",
                  readonly: "",
                  rows: "3",
                  "hide-details": "auto",
                },
                model: {
                  value: _vm.descrizione,
                  callback: function ($$v) {
                    _vm.descrizione = $$v
                  },
                  expression: "descrizione",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }