import { render, staticRenderFns } from "./PgaOfferEvaluationAttachmentsDetails.vue?vue&type=template&id=fbb87cde&scoped=true&"
import script from "./PgaOfferEvaluationAttachmentsDetails.vue?vue&type=script&lang=ts&"
export * from "./PgaOfferEvaluationAttachmentsDetails.vue?vue&type=script&lang=ts&"
import style0 from "./PgaOfferEvaluationAttachmentsDetails.vue?vue&type=style&index=0&id=fbb87cde&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbb87cde",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VCol,VContainer,VLayout,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fbb87cde')) {
      api.createRecord('fbb87cde', component.options)
    } else {
      api.reload('fbb87cde', component.options)
    }
    module.hot.accept("./PgaOfferEvaluationAttachmentsDetails.vue?vue&type=template&id=fbb87cde&scoped=true&", function () {
      api.rerender('fbb87cde', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/feature/Offerte/OfferEvaluation/components/details/sub-components/PgaOfferEvaluationAttachmentsDetails.vue"
export default component.exports