var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c("pga-select", {
                attrs: {
                  label: "ACOM Outcoming",
                  items: _vm.ACOMDropdown,
                  "item-text": "FullName",
                  "item-value": "Id",
                },
                on: { input: _vm.getCustomerOrdersByACOMId },
                model: {
                  value: _vm.ACOMOutcomingId,
                  callback: function ($$v) {
                    _vm.ACOMOutcomingId = $$v
                  },
                  expression: "ACOMOutcomingId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("pga-data-table", {
            attrs: {
              title: "CustomerOrders",
              headers: _vm.headers,
              data: _vm.listCustomerOrders,
              loading: _vm.isLoading,
              "item-key": "Id",
              "col-props": ["ACOMIncoming"],
              "show-select": "",
            },
            scopedSlots: _vm._u([
              {
                key: "column-ACOMIncoming",
                fn: function (props) {
                  return [
                    _vm.showACOMIncoming(props.row.item.Id)
                      ? _c("pga-select", {
                          attrs: {
                            value: _vm.getACOMIncoming(props.row.item.Id),
                            "item-text": "FullName",
                            "item-value": "Id",
                            attach: false,
                            items: _vm.ACOMDropdown,
                            "key-error": _vm.getErrorKeyACOMIncoming(
                              props.row.item.Id
                            ),
                            errors: _vm.Errors,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.setACOMIncoming(
                                props.row.item.Id,
                                $event
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selectedCustomerOrders,
              callback: function ($$v) {
                _vm.selectedCustomerOrders = $$v
              },
              expression: "selectedCustomerOrders",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }