var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditOfferEvaluationCustomerOrder" } },
            [
              _c("pga-create-button", {
                attrs: { text: "Create External Project" },
                on: {
                  click: function ($event) {
                    return _vm.Navigate(
                      "/offerevaluation/customerorder/selectdeal"
                    )
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "pga-authorized-content",
            {
              attrs: { permission: "ReassignACOMOfferEvaluationCustomerOrder" },
            },
            [
              _c("pga-general-button", {
                attrs: {
                  icon: "mdi-transit-transfer",
                  text: "Reassignment ACOM",
                },
                on: { click: _vm.NavigateReassignmentACOM },
              }),
            ],
            1
          ),
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditOfferEvaluationInternalProject" } },
            [
              _c("pga-create-button", {
                attrs: { text: "Create Internal Project" },
                on: {
                  click: function ($event) {
                    return _vm.Navigate(
                      "/offerevaluation/internalproject/create"
                    )
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { elevation: "10" } },
        [
          _c(
            "v-card-title",
            { staticClass: "text-center justify-center py-6" },
            [
              _c("h2", { staticClass: "font-weight-bold display-1" }, [
                _vm._v(" Proposal Evaluation "),
              ]),
            ]
          ),
          _c(
            "v-tabs",
            { attrs: { "background-color": "transparent", grow: "" } },
            [
              _vm.canReadCutomerOrdersTable
                ? _c("v-tab", [_vm._v(" External Projects ")])
                : _vm._e(),
              _vm.canReadInternalProjectsTable
                ? _c("v-tab", [_vm._v(" Internal Projects ")])
                : _vm._e(),
              _vm.canReadCutomerOrdersTable
                ? _c(
                    "v-tab-item",
                    [_c("pga-offer-evaluation-customer-order-table")],
                    1
                  )
                : _vm._e(),
              _vm.canReadInternalProjectsTable
                ? _c(
                    "v-tab-item",
                    [_c("pga-offer-evaluation-inner-project-table")],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }