var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Cliente",
                  rules: [
                    function (v) {
                      return !!v || "Campo obbligatorio"
                    },
                  ],
                },
                model: {
                  value: _vm.cliente,
                  callback: function ($$v) {
                    _vm.cliente = $$v
                  },
                  expression: "cliente",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Protocollo Ordine/Contatto",
                  rules: [
                    function (v) {
                      return !!v || "Campo obbligatorio"
                    },
                  ],
                },
                model: {
                  value: _vm.protocollo,
                  callback: function ($$v) {
                    _vm.protocollo = $$v
                  },
                  expression: "protocollo",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.TipologiaOffertaItems,
                  label: "Tipologia Attività",
                  rules: [
                    function (v) {
                      return !!v || "Campo obbligatorio"
                    },
                  ],
                },
                model: {
                  value: _vm.TipologiaOfferta,
                  callback: function ($$v) {
                    _vm.TipologiaOfferta = $$v
                  },
                  expression: "TipologiaOfferta",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-textarea", {
            attrs: { label: "Descrizione Offerta" },
            model: {
              value: _vm.descrizione,
              callback: function ($$v) {
                _vm.descrizione = $$v
              },
              expression: "descrizione",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }