var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4" } },
            [
              _c("v-chip", { staticClass: "ma-2" }, [
                _vm._v("Other Components Subtotals"),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("pga-currency-field", {
                attrs: { label: "Proposal Amount", readonly: "" },
                model: {
                  value: _vm.offerAmount,
                  callback: function ($$v) {
                    _vm.offerAmount = $$v
                  },
                  expression: "offerAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("pga-currency-field", {
                attrs: { label: "Expected Cost", readonly: "" },
                model: {
                  value: _vm.expectedCost,
                  callback: function ($$v) {
                    _vm.expectedCost = $$v
                  },
                  expression: "expectedCost",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("pga-currency-field", {
                attrs: { label: "Expected Margin", readonly: "" },
                model: {
                  value: _vm.expectedMargin,
                  callback: function ($$v) {
                    _vm.expectedMargin = $$v
                  },
                  expression: "expectedMargin",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("pga-text-field", {
                attrs: {
                  label: "Expected Margin %",
                  readonly: "",
                  prefix: "%",
                },
                model: {
                  value: _vm.expectedMarginPerc,
                  callback: function ($$v) {
                    _vm.expectedMarginPerc = $$v
                  },
                  expression: "expectedMarginPerc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }