var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12" } },
            [
              _c("pga-text-area", {
                attrs: {
                  label: "Notes",
                  "key-error": "Attachments.Notes",
                  errors: _vm.Errors,
                  readonly: _vm.restricted,
                },
                model: {
                  value: _vm.notes,
                  callback: function ($$v) {
                    _vm.notes = $$v
                  },
                  expression: "notes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          !_vm.restricted
            ? _c(
                "v-col",
                {
                  staticClass: "comp",
                  attrs: { cols: "12", sm: "12", md: "4" },
                },
                _vm._l(_vm.files.length + 1, function (index, i) {
                  return _c(
                    "v-layout",
                    { key: i },
                    [
                      _c("pga-input-file", {
                        attrs: {
                          value: _vm.getFile(i),
                          label: "Documents",
                          "key-error": "Files",
                          errors: _vm.Errors,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.setFile($event, i)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function (props) {
                                return [
                                  _c("v-chip", { attrs: { small: "" } }, [
                                    _vm._v(" " + _vm._s(props.row.text) + " "),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _c("v-col", { staticClass: "comp", attrs: { cols: "12", sm: "8" } }, [
            _c(
              "div",
              {
                staticClass:
                  "v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-select v-text-field__slot",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "label",
                      {
                        staticClass: "v-label v-label--active theme--light",
                        staticStyle: {
                          left: "0px",
                          right: "auto",
                          position: "absolute",
                        },
                      },
                      [_vm._v(" Documents Loaded ")]
                    ),
                    _vm._l(_vm.filesLoaded, function (file, i) {
                      return _c(
                        "div",
                        { key: i },
                        [
                          _c(
                            "v-chip",
                            {
                              attrs: { close: "", "close-icon": "mdi-delete" },
                              on: {
                                "click:close": function ($event) {
                                  return _vm.removeDocument(file)
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.getFileName(file)) + " "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    _vm.filesLoaded.length < 1
                      ? _c("div", [
                          _c("em", [_vm._v(" There are no documents loaded ")]),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }