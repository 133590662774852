import { render, staticRenderFns } from "./ValutazioneOffertaPageCreate.vue?vue&type=template&id=2a8daed4&"
import script from "./ValutazioneOffertaPageCreate.vue?vue&type=script&lang=ts&"
export * from "./ValutazioneOffertaPageCreate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VCard,VDivider,VExpansionPanels,VForm,VIcon,VRow,VStepper,VStepperContent,VStepperStep})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a8daed4')) {
      api.createRecord('2a8daed4', component.options)
    } else {
      api.reload('2a8daed4', component.options)
    }
    module.hot.accept("./ValutazioneOffertaPageCreate.vue?vue&type=template&id=2a8daed4&", function () {
      api.rerender('2a8daed4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/feature/Offerte/ValutazioneOfferta/ValutazioneOffertaPageCreate.vue"
export default component.exports