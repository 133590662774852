var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  ' Internal Project "' + _vm._s(_vm.title) + '" - Details '
                ),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "8" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "Title", readonly: "" },
                            model: {
                              value: _vm.title,
                              callback: function ($$v) {
                                _vm.title = $$v
                              },
                              expression: "title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-date-picker", {
                            attrs: { label: "Date", readonly: "" },
                            model: {
                              value: _vm.date,
                              callback: function ($$v) {
                                _vm.date = $$v
                              },
                              expression: "date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "Business Line", readonly: "" },
                            model: {
                              value: _vm.businessLine,
                              callback: function ($$v) {
                                _vm.businessLine = $$v
                              },
                              expression: "businessLine",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "Tech Area", readonly: "" },
                            model: {
                              value: _vm.techArea,
                              callback: function ($$v) {
                                _vm.techArea = $$v
                              },
                              expression: "techArea",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "8", lg: "6" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "Supply Tipology", readonly: "" },
                            model: {
                              value: _vm.supplyTipology,
                              callback: function ($$v) {
                                _vm.supplyTipology = $$v
                              },
                              expression: "supplyTipology",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("pga-switch", {
                            attrs: {
                              title: "DG Project Approval",
                              disabled: "",
                            },
                            model: {
                              value: _vm.approvalProjectDG,
                              callback: function ($$v) {
                                _vm.approvalProjectDG = $$v
                              },
                              expression: "approvalProjectDG",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("pga-switch", {
                            attrs: {
                              title: "DG Spending Budget Approval",
                              disabled: "",
                            },
                            model: {
                              value: _vm.approvalSpendingBudgetDG,
                              callback: function ($$v) {
                                _vm.approvalSpendingBudgetDG = $$v
                              },
                              expression: "approvalSpendingBudgetDG",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("pga-switch", {
                            attrs: { title: "SG Review", disabled: "" },
                            model: {
                              value: _vm.reviewSG,
                              callback: function ($$v) {
                                _vm.reviewSG = $$v
                              },
                              expression: "reviewSG",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c("v-divider"),
          _c("br"),
          _c(
            "v-expansion-panels",
            {
              attrs: { multiple: "" },
              model: {
                value: _vm.panelsOpened,
                callback: function ($$v) {
                  _vm.panelsOpened = $$v
                },
                expression: "panelsOpened",
              },
            },
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(" Details Internal Project "),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "pga-offer-evaluation-details-internal-project-details",
                        {
                          model: {
                            value: _vm.Model.Details,
                            callback: function ($$v) {
                              _vm.$set(_vm.Model, "Details", $$v)
                            },
                            expression: "Model.Details",
                          },
                        }
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v(" Attachments ")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("pga-offer-evaluation-attachments-details", {
                        model: {
                          value: _vm.Model.Attachments,
                          callback: function ($$v) {
                            _vm.$set(_vm.Model, "Attachments", $$v)
                          },
                          expression: "Model.Attachments",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }