var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    [
      _c("v-card-title", [
        _vm._v(
          ' Business Startup for External Project "' +
            _vm._s(_vm.customerOrder.OrderRequirementsReview.Customer) +
            '" - Details '
        ),
      ]),
      _c(
        "v-expansion-panels",
        {
          attrs: { multiple: "" },
          model: {
            value: _vm.panelsOpened,
            callback: function ($$v) {
              _vm.panelsOpened = $$v
            },
            expression: "panelsOpened",
          },
        },
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _vm._v(" Order Requirements Review "),
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c("pga-order-requirements-review-details", {
                    model: {
                      value: _vm.customerOrder.OrderRequirementsReview,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.customerOrder,
                          "OrderRequirementsReview",
                          $$v
                        )
                      },
                      expression: "customerOrder.OrderRequirementsReview",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }