var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-data-table", {
    attrs: {
      title: "Business Startup Internal Project Modules",
      headers: _vm.headers,
      data: _vm.internalProjectList,
      loading: _vm.isLoading,
      "col-props": ["StartDate", "ExpectedEndDate"],
      "show-expand": "",
    },
    on: { "row-clicked": _vm.details },
    scopedSlots: _vm._u([
      {
        key: "column-StartDate",
        fn: function (props) {
          return [
            _vm._v(
              " " + _vm._s(_vm.getFormattedDate(props.row.item.StartDate)) + " "
            ),
          ]
        },
      },
      {
        key: "column-ExpectedEndDate",
        fn: function (props) {
          return [
            _vm._v(
              " " +
                _vm._s(_vm.getFormattedDate(props.row.item.ExpectedEndDate)) +
                " "
            ),
          ]
        },
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("pga-switch", {
              staticClass: "pa-0 pl-2",
              staticStyle: { position: "absolute" },
              attrs: { label: "Show Projects Closed" },
              on: { change: _vm.getInternalProjectsClosed },
              model: {
                value: _vm.showClosed,
                callback: function ($$v) {
                  _vm.showClosed = $$v
                },
                expression: "showClosed",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }