var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-12" },
    [
      _c(
        "v-card-title",
        [
          _vm._v(" Costi Aggiuntivi Presale "),
          _c("v-spacer"),
          _c("v-text-field", {
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              "hide-details": "",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.AggiuntiviPresaleItems,
          search: _vm.search,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }