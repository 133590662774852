var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v("Totali Offerta"),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: { label: "Importo Offerta", prefix: "€", readonly: "" },
                model: {
                  value: _vm.importoOffertaTotale,
                  callback: function ($$v) {
                    _vm.importoOffertaTotale = $$v
                  },
                  expression: "importoOffertaTotale",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: { label: "Costo Atteso", prefix: "€", readonly: "" },
                model: {
                  value: _vm.costoAttesoTotale,
                  callback: function ($$v) {
                    _vm.costoAttesoTotale = $$v
                  },
                  expression: "costoAttesoTotale",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: { label: "Margine Atteso", prefix: "€", readonly: "" },
                model: {
                  value: _vm.margineAttesoTotale,
                  callback: function ($$v) {
                    _vm.margineAttesoTotale = $$v
                  },
                  expression: "margineAttesoTotale",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: { label: "Margine Atteso %", prefix: "%", readonly: "" },
                model: {
                  value: _vm.margineAttesoPercTotale,
                  callback: function ($$v) {
                    _vm.margineAttesoPercTotale = $$v
                  },
                  expression: "margineAttesoPercTotale",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v("Sconto"),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  readonly: "",
                  label: "Sconto",
                  prefix: "%",
                  type: "number",
                },
                model: {
                  value: _vm.sconto,
                  callback: function ($$v) {
                    _vm.sconto = $$v
                  },
                  expression: "sconto",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v("Totali Offerta Scontati"),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: { label: "Importo Offerta", prefix: "€", readonly: "" },
                model: {
                  value: _vm.importoOffertaTotaleScontato,
                  callback: function ($$v) {
                    _vm.importoOffertaTotaleScontato = $$v
                  },
                  expression: "importoOffertaTotaleScontato",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "CostoAtteso",
                  prefix: "€",
                  type: "number",
                  readonly: "",
                },
                model: {
                  value: _vm.costoAttesoTotaleScontato,
                  callback: function ($$v) {
                    _vm.costoAttesoTotaleScontato = $$v
                  },
                  expression: "costoAttesoTotaleScontato",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Margine Atteso",
                  prefix: "€",
                  type: "number",
                  readonly: "",
                },
                model: {
                  value: _vm.margineAttesoTotaleScontato,
                  callback: function ($$v) {
                    _vm.margineAttesoTotaleScontato = $$v
                  },
                  expression: "margineAttesoTotaleScontato",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Margine Atteso %",
                  prefix: "%",
                  type: "number",
                  readonly: "",
                },
                model: {
                  value: _vm.margineAttesoPercTotaleScontato,
                  callback: function ($$v) {
                    _vm.margineAttesoPercTotaleScontato = $$v
                  },
                  expression: "margineAttesoPercTotaleScontato",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }