import { render, staticRenderFns } from "./PgaOfferEvaluationOtherCostsDetails.vue?vue&type=template&id=0924c07d&"
import script from "./PgaOfferEvaluationOtherCostsDetails.vue?vue&type=script&lang=ts&"
export * from "./PgaOfferEvaluationOtherCostsDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VFadeTransition,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0924c07d')) {
      api.createRecord('0924c07d', component.options)
    } else {
      api.reload('0924c07d', component.options)
    }
    module.hot.accept("./PgaOfferEvaluationOtherCostsDetails.vue?vue&type=template&id=0924c07d&", function () {
      api.rerender('0924c07d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/feature/Offerte/OfferEvaluation/components/details/sub-components/PgaOfferEvaluationOtherCostsDetails.vue"
export default component.exports