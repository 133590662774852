var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.customerOrderDetails.Versions.length === 0
        ? _c(
            "pga-bottom-navigation",
            { attrs: { "is-loaded": _vm.isLoaded } },
            [
              _vm.editRestricted
                ? _c(
                    "pga-authorized-content",
                    {
                      attrs: {
                        permission:
                          "EditOfferEvaluationCustomerOrderRestricted",
                      },
                    },
                    [_c("pga-edit-button", { on: { click: _vm.Edit } })],
                    1
                  )
                : _c(
                    "pga-authorized-content",
                    {
                      attrs: { permission: "EditOfferEvaluationCustomerOrder" },
                    },
                    [
                      _vm.isEditable
                        ? _c("pga-edit-button", { on: { click: _vm.Edit } })
                        : _vm._e(),
                      _vm.isActivable
                        ? _c("pga-general-button", {
                            attrs: { icon: "mdi-auto-upload", text: "Submit" },
                            on: { click: _vm.Active },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
              _c("pga-authorized-only-one-content", {
                attrs: {
                  permissions: [
                    "EditOfferEvaluationCustomerOrder",
                    "OfferEvaluationCustomerOrderReviewSG",
                  ],
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "EditOfferEvaluationCustomerOrder",
                      fn: function () {
                        return [
                          _vm.isApprovalRequestableByACOMorDC
                            ? _c("pga-general-button", {
                                attrs: {
                                  icon: "mdi-map-marker-question",
                                  text: "Request Approval",
                                },
                                on: { click: _vm.RequestApproval },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "OfferEvaluationCustomerOrderReviewSG",
                      fn: function () {
                        return [
                          _vm.isApprovalRequestableOrApprovableBySG
                            ? _c("pga-general-button", {
                                attrs: {
                                  icon: "mdi-map-marker-question",
                                  text: "Request Approval DHRG",
                                },
                                on: { click: _vm.RequestApproval },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4235221713
                ),
              }),
              _c(
                "pga-authorized-content",
                {
                  attrs: {
                    permission: "OfferEvaluationCustomerOrderApproveOfferDC",
                  },
                },
                [
                  _vm.isApprovableByDC
                    ? _c("pga-approve-button", { on: { click: _vm.Approve } })
                    : _vm._e(),
                  _vm.isApprovableByDC
                    ? _c("pga-request-changes-button", {
                        on: { click: _vm.RequestChanges },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "pga-authorized-content",
                {
                  attrs: {
                    permission:
                      "OfferEvaluationCustomerOrderApproveOfferLimitDG",
                  },
                },
                [
                  _vm.isApprovableByDG
                    ? _c("pga-approve-button", { on: { click: _vm.Approve } })
                    : _vm._e(),
                  _vm.isApprovableByDG
                    ? _c("pga-request-changes-button", {
                        on: { click: _vm.RequestChanges },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "pga-authorized-content",
                {
                  attrs: { permission: "OfferEvaluationCustomerOrderReviewSG" },
                },
                [
                  _vm.isApprovalRequestableOrApprovableBySG
                    ? _c("pga-approve-button", { on: { click: _vm.Approve } })
                    : _vm._e(),
                  _vm.isApprovalRequestableOrApprovableBySG
                    ? _c("pga-request-changes-button", {
                        on: { click: _vm.RequestChanges },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "pga-authorized-content",
                {
                  attrs: {
                    permission: "OfferEvaluationCustomerOrderApproveDHRG",
                  },
                },
                [
                  _vm.isApprovableByDHRG
                    ? _c("pga-approve-button", { on: { click: _vm.Approve } })
                    : _vm._e(),
                  _vm.isApprovableByDHRG
                    ? _c("pga-request-changes-button", {
                        on: { click: _vm.RequestChanges },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditOfferEvaluationCustomerOrder" } },
                [
                  _vm.canCreateAssessment
                    ? _c("pga-edit-button", {
                        attrs: { text: "Change" },
                        on: { click: _vm.CreateAssessment },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditOfferEvaluationCustomerOrder" } },
                [
                  _vm.canCreateAssessment
                    ? _c("pga-edit-button", {
                        attrs: { text: "Change" },
                        on: { click: _vm.CreateAssessment },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "pga-authorized-content",
                { attrs: { permission: "DeleteOfferEvaluationCustomerOrder" } },
                [
                  _vm.isDeletable
                    ? _c("pga-delete-button", {
                        on: {
                          click: function ($event) {
                            _vm.showDialog = true
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "pga-bottom-navigation",
            { attrs: { "is-loaded": _vm.isLoaded } },
            [
              _vm.editRestricted
                ? _c(
                    "pga-authorized-content",
                    {
                      attrs: {
                        permission:
                          "EditOfferEvaluationCustomerOrderRestricted",
                      },
                    },
                    [_c("pga-edit-button", { on: { click: _vm.Edit } })],
                    1
                  )
                : _c(
                    "pga-authorized-content",
                    {
                      attrs: { permission: "EditOfferEvaluationCustomerOrder" },
                    },
                    [
                      _vm.isEditable
                        ? _c("pga-edit-button", { on: { click: _vm.Edit } })
                        : _vm._e(),
                      _vm.isNotificableAssessment
                        ? _c("pga-general-button", {
                            attrs: {
                              icon: "mdi-auto-upload",
                              text: "Notify Assessment",
                            },
                            on: { click: _vm.NotifyAssessment },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditOfferEvaluationCustomerOrder" } },
                [
                  _vm.isApprovalRequestableAssessment
                    ? _c("pga-general-button", {
                        attrs: {
                          icon: "mdi-map-marker-question",
                          text: "Request Approval Assessment",
                        },
                        on: { click: _vm.RequestApprovalAssessment },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("pga-authorized-only-one-content", {
                attrs: {
                  permissions: [
                    "OfferEvaluationCustomerOrderApproveOfferDC",
                    "OfferEvaluationCustomerOrderApproveOfferLimitDG",
                    "OfferEvaluationCustomerOrderReviewSG",
                  ],
                },
                scopedSlots: _vm._u([
                  {
                    key: "OfferEvaluationCustomerOrderApproveOfferDC",
                    fn: function () {
                      return [
                        _vm.isApprovableAssessmentByDC
                          ? _c("pga-approve-button", {
                              on: { click: _vm.ApproveAssessment },
                            })
                          : _vm._e(),
                        _vm.isApprovableAssessmentByDC
                          ? _c("pga-request-changes-button", {
                              on: { click: _vm.RequestChanges },
                            })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "OfferEvaluationCustomerOrderApproveOfferLimitDG",
                    fn: function () {
                      return [
                        _vm.isApprovableAssessmentByDG
                          ? _c("pga-approve-button", {
                              on: { click: _vm.ApproveAssessment },
                            })
                          : _vm._e(),
                        _vm.isApprovableAssessmentByDG
                          ? _c("pga-request-changes-button", {
                              on: { click: _vm.RequestChanges },
                            })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "OfferEvaluationCustomerOrderReviewSG",
                    fn: function () {
                      return [
                        _vm.isApprovableAssessmentBySG
                          ? _c("pga-approve-button", {
                              on: { click: _vm.ApproveAssessment },
                            })
                          : _vm._e(),
                        _vm.isApprovableAssessmentBySG
                          ? _c("pga-request-changes-button", {
                              on: { click: _vm.RequestChanges },
                            })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditOfferEvaluationCustomerOrder" } },
                [
                  _vm.canCreateAssessment
                    ? _c("pga-edit-button", {
                        attrs: { text: "Change" },
                        on: { click: _vm.CreateAssessment },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "pga-authorized-content",
                { attrs: { permission: "DeleteOfferEvaluationCustomerOrder" } },
                [
                  _vm.isDeletable
                    ? _c("pga-delete-button", {
                        on: {
                          click: function ($event) {
                            _vm.showDialog = true
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "pga-skeleton-loader",
        { attrs: { "is-loaded": _vm.isLoaded } },
        [
          _vm.customerOrderDetails.Versions.length === 0
            ? _c("pga-offer-evaluation-customer-order-details", {
                model: {
                  value: _vm.customerOrderDetails,
                  callback: function ($$v) {
                    _vm.customerOrderDetails = $$v
                  },
                  expression: "customerOrderDetails",
                },
              })
            : _c(
                "v-tabs",
                { attrs: { "background-color": "transparent", grow: "" } },
                [
                  _c("v-tab", [_vm._v(" Current ")]),
                  _c(
                    "v-tab-item",
                    [
                      _c("pga-offer-evaluation-customer-order-details", {
                        model: {
                          value: _vm.customerOrderDetails,
                          callback: function ($$v) {
                            _vm.customerOrderDetails = $$v
                          },
                          expression: "customerOrderDetails",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.customerOrderDetails.Versions, function (ver, i) {
                    return _c("v-tab", { key: i }, [
                      _vm._v(
                        " Version " +
                          _vm._s(ver.Version) +
                          " - " +
                          _vm._s(_vm.formatDate(new Date(ver.LastModified))) +
                          " "
                      ),
                    ])
                  }),
                  _vm._l(_vm.customerOrderDetails.Versions, function (ver, i) {
                    return _c(
                      "v-tab-item",
                      { key: i },
                      [
                        _c("pga-offer-evaluation-customer-order-details", {
                          model: {
                            value: ver.CustomerOrder,
                            callback: function ($$v) {
                              _vm.$set(ver, "CustomerOrder", $$v)
                            },
                            expression: "ver.CustomerOrder",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
          _c("pga-dialog", {
            attrs: {
              title: "Deleting Proposal Evaluation External Project",
              "icon-title": "mdi-delete-alert",
              message:
                "Are you sure you want to delete the External \n\t\t\t\t\tProject of '" +
                _vm.customerOrderDetails.CustomerName +
                "'?",
              color: "orange",
              "icon-button": "mdi-checkbox-marked-circle-outline",
            },
            on: {
              agree: _vm.Delete,
              reject: function ($event) {
                _vm.showDialog = false
              },
            },
            model: {
              value: _vm.showDialog,
              callback: function ($$v) {
                _vm.showDialog = $$v
              },
              expression: "showDialog",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }