var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    [
      _c(
        "v-card",
        { staticClass: "mb-12", attrs: { tile: "" } },
        [
          _c("pga-valutazione-offerta-header-details", {
            model: {
              value: _vm.PgaValutazioneOffertaHeaderDetails,
              callback: function ($$v) {
                _vm.PgaValutazioneOffertaHeaderDetails = $$v
              },
              expression: "PgaValutazioneOffertaHeaderDetails",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-12", attrs: { tile: "" } },
        [
          _c("v-card-title", { staticClass: "pa-2" }, [
            _vm._v("Risorse Umane"),
          ]),
          _c(
            "v-container",
            { staticClass: "pa-1", attrs: { fluid: "" } },
            [
              _c("pga-valutazione-offerta-risorse-umane-details"),
              _c("pga-valutazione-offerta-subtotali-risorse-umane", {
                model: {
                  value: _vm.PgaValutazioneOffertaSubtotaliRisorseUmane,
                  callback: function ($$v) {
                    _vm.PgaValutazioneOffertaSubtotaliRisorseUmane = $$v
                  },
                  expression: "PgaValutazioneOffertaSubtotaliRisorseUmane",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-12" },
        [
          _c("pga-valutazione-offerta-altre-componenti-details"),
          _c("pga-valutazione-offerta-subtotali-altre-componenti", {
            model: {
              value: _vm.PgaValutazioneOffertaSubtotaliAltreComponenti,
              callback: function ($$v) {
                _vm.PgaValutazioneOffertaSubtotaliAltreComponenti = $$v
              },
              expression: "PgaValutazioneOffertaSubtotaliAltreComponenti",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-12" },
        [
          _c("pga-valutazione-offerta-aggiuntivi-presale-details"),
          _c("pga-valutazione-offerta-subtotali-aggiuntivi-presale", {
            model: {
              value: _vm.PgaValutazioneOffertaSubtotaliAggiuntiviPresale,
              callback: function ($$v) {
                _vm.PgaValutazioneOffertaSubtotaliAggiuntiviPresale = $$v
              },
              expression: "PgaValutazioneOffertaSubtotaliAggiuntiviPresale",
            },
          }),
          _c("pga-valutazione-offerta-altri-presale-details"),
          _c("pga-valutazione-offerta-subtotali-altri-presale", {
            model: {
              value: _vm.PgaValutazioneOffertaSubtotaliAltriPresale,
              callback: function ($$v) {
                _vm.PgaValutazioneOffertaSubtotaliAltriPresale = $$v
              },
              expression: "PgaValutazioneOffertaSubtotaliAltriPresale",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-12" },
        [
          _c("pga-valutazione-offerta-postsale-details"),
          _c("pga-valutazione-offerta-subtotali-postsale", {
            model: {
              value: _vm.PgaValutazioneOffertaSubtotaliPostsale,
              callback: function ($$v) {
                _vm.PgaValutazioneOffertaSubtotaliPostsale = $$v
              },
              expression: "PgaValutazioneOffertaSubtotaliPostsale",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-12" },
        [
          _c("pga-valutazione-offerta-totali-offerta-details", {
            model: {
              value: _vm.PgaValutazioneOffertaTotaliOffertaDetails,
              callback: function ($$v) {
                _vm.PgaValutazioneOffertaTotaliOffertaDetails = $$v
              },
              expression: "PgaValutazioneOffertaTotaliOffertaDetails",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-12" },
        [
          _c("pga-valutazione-offerta-totali-offerta-valutazione", {
            model: {
              value: _vm.PgaValutazioneOffertaTotaliOffertaValutazione,
              callback: function ($$v) {
                _vm.PgaValutazioneOffertaTotaliOffertaValutazione = $$v
              },
              expression: "PgaValutazioneOffertaTotaliOffertaValutazione",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }