var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2", "no-gutters": "" } },
            [
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v("Subtotali Risorse Umane"),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "1" } },
            [
              _c("v-text-field", {
                attrs: { label: "GG/Uomo Previsti", readonly: "", prefix: "€" },
                model: {
                  value: _vm.ggUomoPrev,
                  callback: function ($$v) {
                    _vm.ggUomoPrev = $$v
                  },
                  expression: "ggUomoPrev",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "1" } },
            [
              _c("v-text-field", {
                attrs: { label: "GG/Uomo Offerta", readonly: "", prefix: "€" },
                model: {
                  value: _vm.ggUomoOff,
                  callback: function ($$v) {
                    _vm.ggUomoOff = $$v
                  },
                  expression: "ggUomoOff",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "1" } },
            [
              _c("v-text-field", {
                attrs: { label: "Margine Rischio", readonly: "", prefix: "%" },
                model: {
                  value: _vm.margineRischio,
                  callback: function ($$v) {
                    _vm.margineRischio = $$v
                  },
                  expression: "margineRischio",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("v-text-field", {
                attrs: { label: "Importo Offerta", readonly: "", prefix: "€" },
                model: {
                  value: _vm.importoOfferta,
                  callback: function ($$v) {
                    _vm.importoOfferta = $$v
                  },
                  expression: "importoOfferta",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("v-text-field", {
                attrs: { label: "Costo Atteso", readonly: "", prefix: "€" },
                model: {
                  value: _vm.costoAtteso,
                  callback: function ($$v) {
                    _vm.costoAtteso = $$v
                  },
                  expression: "costoAtteso",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("v-text-field", {
                attrs: { label: "Margine Atteso", readonly: "", prefix: "€" },
                model: {
                  value: _vm.margineAtteso,
                  callback: function ($$v) {
                    _vm.margineAtteso = $$v
                  },
                  expression: "margineAtteso",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "1" } },
            [
              _c("v-text-field", {
                attrs: { label: "Margine Atteso", readonly: "", prefix: "%" },
                model: {
                  value: _vm.margineAttesoPerc,
                  callback: function ($$v) {
                    _vm.margineAttesoPerc = $$v
                  },
                  expression: "margineAttesoPerc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }