var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("pga-text-field", {
                attrs: { label: "ACOM", readonly: "" },
                model: {
                  value: _vm.acom,
                  callback: function ($$v) {
                    _vm.acom = $$v
                  },
                  expression: "acom",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-dropdown-search", {
                attrs: {
                  items: _vm.customersDropdown,
                  label: "Customer Name",
                  "show-menu": _vm.showDynamicDropdown,
                  loading: _vm.dropdownLoading,
                  "key-error": "CustomerName",
                  errors: _vm.Errors,
                  readonly: _vm.restricted,
                },
                on: {
                  click: function ($event) {
                    _vm.showDynamicDropdown = !_vm.showDynamicDropdown
                  },
                  "search-input": _vm.setCustomerNameManually,
                },
                model: {
                  value: _vm.customerName,
                  callback: function ($$v) {
                    _vm.customerName = $$v
                  },
                  expression: "customerName",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-text-field", {
                attrs: {
                  label: "Description",
                  "key-error": "Description",
                  errors: _vm.Errors,
                  readonly: _vm.restricted,
                },
                model: {
                  value: _vm.offerDescription,
                  callback: function ($$v) {
                    _vm.offerDescription = $$v
                  },
                  expression: "offerDescription",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-text-field", {
                attrs: {
                  label: "Order Protocol/Agreement",
                  "key-error": "OrderProtocol",
                  errors: _vm.Errors,
                  readonly: !_vm.restricted,
                },
                model: {
                  value: _vm.protocol,
                  callback: function ($$v) {
                    _vm.protocol = $$v
                  },
                  expression: "protocol",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-select", {
                attrs: {
                  label: "Order Tipology",
                  "item-value": "Value",
                  "item-text": "Name",
                  items: _vm.orderTipology,
                  readonly: _vm.restricted,
                },
                model: {
                  value: _vm.type,
                  callback: function ($$v) {
                    _vm.type = $$v
                  },
                  expression: "type",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "2" } },
            [
              _c("pga-switch", {
                attrs: {
                  title: "Master Agreement",
                  label: _vm.masterAgreement ? "Yes" : "No",
                  inset: "",
                  readonly: _vm.restricted,
                },
                model: {
                  value: _vm.masterAgreement,
                  callback: function ($$v) {
                    _vm.masterAgreement = $$v
                  },
                  expression: "masterAgreement",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-text-field", {
                attrs: { label: "CRM Deal Id", readonly: "" },
                model: {
                  value: _vm.crmDealId,
                  callback: function ($$v) {
                    _vm.crmDealId = $$v
                  },
                  expression: "crmDealId",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-currency-field", {
                attrs: { label: "CRM Amount", readonly: "" },
                model: {
                  value: _vm.crmAmount,
                  callback: function ($$v) {
                    _vm.crmAmount = $$v
                  },
                  expression: "crmAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-date-picker", {
                attrs: { label: "CRM Last Update", readonly: "" },
                model: {
                  value: _vm.crmLastUpdate,
                  callback: function ($$v) {
                    _vm.crmLastUpdate = $$v
                  },
                  expression: "crmLastUpdate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }