var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("pga-text-field", {
                attrs: { label: "ACOM", readonly: "" },
                model: {
                  value: _vm.ACOM,
                  callback: function ($$v) {
                    _vm.ACOM = $$v
                  },
                  expression: "ACOM",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("pga-text-field", {
                attrs: { label: "Customer", readonly: "" },
                model: {
                  value: _vm.customer,
                  callback: function ($$v) {
                    _vm.customer = $$v
                  },
                  expression: "customer",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("pga-text-field", {
                attrs: { label: "Final Customer", readonly: "" },
                model: {
                  value: _vm.finalCustomer,
                  callback: function ($$v) {
                    _vm.finalCustomer = $$v
                  },
                  expression: "finalCustomer",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("pga-text-field", {
                attrs: { label: "Description", readonly: "" },
                model: {
                  value: _vm.description,
                  callback: function ($$v) {
                    _vm.description = $$v
                  },
                  expression: "description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "2" } },
            [
              _c("pga-switch", {
                attrs: { title: "Advance Supply", inset: "", disabled: "" },
                model: {
                  value: _vm.advanceSupply,
                  callback: function ($$v) {
                    _vm.advanceSupply = $$v
                  },
                  expression: "advanceSupply",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-date-picker", {
                attrs: { label: "Start Date", readonly: "" },
                model: {
                  value: _vm.startDate,
                  callback: function ($$v) {
                    _vm.startDate = $$v
                  },
                  expression: "startDate",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-date-picker", {
                attrs: { label: "Expected End Date", readonly: "" },
                model: {
                  value: _vm.expectedEndDate,
                  callback: function ($$v) {
                    _vm.expectedEndDate = $$v
                  },
                  expression: "expectedEndDate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("pga-text-field", {
                attrs: { label: "Place", readonly: "" },
                model: {
                  value: _vm.place,
                  callback: function ($$v) {
                    _vm.place = $$v
                  },
                  expression: "place",
                },
              }),
              _vm.Model.Place === 2
                ? _c("pga-text-field", {
                    attrs: { label: "Address", readonly: "" },
                    model: {
                      value: _vm.address,
                      callback: function ($$v) {
                        _vm.address = $$v
                      },
                      expression: "address",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-text-field", {
                attrs: { label: "Customer Contract Manager", readonly: "" },
                model: {
                  value: _vm.customerContractManager,
                  callback: function ($$v) {
                    _vm.customerContractManager = $$v
                  },
                  expression: "customerContractManager",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            _vm._l(_vm.otherCustomerReferrals, function (item, i) {
              return _c("pga-text-field", {
                key: i,
                attrs: {
                  label: "Other Customer Referrals",
                  value: item,
                  readonly: "",
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
            [
              _c("pga-text-field", {
                attrs: { label: "Business Line", readonly: "" },
                model: {
                  value: _vm.businessLine,
                  callback: function ($$v) {
                    _vm.businessLine = $$v
                  },
                  expression: "businessLine",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
            [
              _c("pga-text-field", {
                attrs: { label: "Tech Area", readonly: "" },
                model: {
                  value: _vm.techArea,
                  callback: function ($$v) {
                    _vm.techArea = $$v
                  },
                  expression: "techArea",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8", lg: "6" } },
            [
              _c("pga-text-field", {
                attrs: { label: "Supply Tipology", readonly: "" },
                model: {
                  value: _vm.supplyTipology,
                  callback: function ($$v) {
                    _vm.supplyTipology = $$v
                  },
                  expression: "supplyTipology",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "2" } },
            [
              _c("pga-text-field", {
                attrs: { label: "Cost Center", readonly: "" },
                model: {
                  value: _vm.areaManager,
                  callback: function ($$v) {
                    _vm.areaManager = $$v
                  },
                  expression: "areaManager",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8" } },
            _vm._l(_vm.deliverables, function (item, i) {
              return _c(
                "v-layout",
                { key: i },
                [
                  _c("pga-text-field", {
                    attrs: { label: "Deliverable Name", readonly: "" },
                    model: {
                      value: item.DeliverableName,
                      callback: function ($$v) {
                        _vm.$set(item, "DeliverableName", $$v)
                      },
                      expression: "item.DeliverableName",
                    },
                  }),
                  _c("pga-date-picker", {
                    attrs: { label: "Delivery By", readonly: "" },
                    model: {
                      value: item.DeliveryBy,
                      callback: function ($$v) {
                        _vm.$set(item, "DeliveryBy", $$v)
                      },
                      expression: "item.DeliveryBy",
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "2" } },
            [
              _c("pga-switch", {
                attrs: { title: "Privacy", inset: "", disabled: "" },
                model: {
                  value: _vm.privacy,
                  callback: function ($$v) {
                    _vm.privacy = $$v
                  },
                  expression: "privacy",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "10" } },
            [
              _c("pga-text-area", {
                attrs: { label: "Notes", readonly: "" },
                model: {
                  value: _vm.notes,
                  callback: function ($$v) {
                    _vm.notes = $$v
                  },
                  expression: "notes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }