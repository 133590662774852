var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "6" } },
        [
          _c("pga-text-field", {
            attrs: { label: "Deliverable Name" },
            model: {
              value: _vm.deliverableName,
              callback: function ($$v) {
                _vm.deliverableName = $$v
              },
              expression: "deliverableName",
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "4" } },
        [
          _c("pga-date-picker", {
            attrs: { label: "Delivery By" },
            model: {
              value: _vm.deliveryBy,
              callback: function ($$v) {
                _vm.deliveryBy = $$v
              },
              expression: "deliveryBy",
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "2" } },
        [
          _vm.index > 0
            ? _c("pga-general-button", {
                attrs: {
                  absolute: "",
                  dark: "",
                  "x-small": "",
                  fab: "",
                  icon: "mdi-minus",
                },
                on: { click: _vm.removeDeliverable },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }