var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    [
      _c("v-expansion-panel-header", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var open = ref.open
              return [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text--secondary", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-fade-transition",
                          { attrs: { "leave-absolute": "" } },
                          [
                            open
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          absolute: "",
                                          dark: "",
                                          fab: "",
                                          center: "",
                                          right: "",
                                          color: "pink",
                                        },
                                        on: { click: _vm.removePostsale },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-minus")])],
                                      1
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        "Dettaglio Costi PgaValutazioneOffertaPostsaleCreate"
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-row",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { "no-gutters": "" },
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        "Costo Atteso: " +
                                          _vm._s(
                                            _vm.CostoAttesoPostsale + "€" ||
                                              "Non impostato"
                                          )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-expansion-panel-content",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "2" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.TipologiaPostsaleItems,
                      label: "Tipologia",
                      required: "",
                      rules: [
                        function (v) {
                          return !!v || "Item is required"
                        },
                      ],
                    },
                    model: {
                      value: _vm.TipologiaPostsale,
                      callback: function ($$v) {
                        _vm.TipologiaPostsale = $$v
                      },
                      expression: "TipologiaPostsale",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      rows: "1",
                      label: "Descrizione",
                      rules: [
                        function (v) {
                          return !!v || "Item is required"
                        },
                      ],
                    },
                    model: {
                      value: _vm.DescrizionePostsale,
                      callback: function ($$v) {
                        _vm.DescrizionePostsale = $$v
                      },
                      expression: "DescrizionePostsale",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "2", md: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      prefix: "€",
                      label: "Costo Atteso",
                      type: "number",
                    },
                    model: {
                      value: _vm.CostoAttesoPostsale,
                      callback: function ($$v) {
                        _vm.CostoAttesoPostsale = $$v
                      },
                      expression: "CostoAttesoPostsale",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }