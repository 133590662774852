import { render, staticRenderFns } from "./PgaOfferEvaluationAttachmentsCreate.vue?vue&type=template&id=3449f837&scoped=true&"
import script from "./PgaOfferEvaluationAttachmentsCreate.vue?vue&type=script&lang=ts&"
export * from "./PgaOfferEvaluationAttachmentsCreate.vue?vue&type=script&lang=ts&"
import style0 from "./PgaOfferEvaluationAttachmentsCreate.vue?vue&type=style&index=0&id=3449f837&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3449f837",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VCol,VContainer,VLayout,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3449f837')) {
      api.createRecord('3449f837', component.options)
    } else {
      api.reload('3449f837', component.options)
    }
    module.hot.accept("./PgaOfferEvaluationAttachmentsCreate.vue?vue&type=template&id=3449f837&scoped=true&", function () {
      api.rerender('3449f837', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/feature/Offerte/OfferEvaluation/components/create/sub-components/PgaOfferEvaluationAttachmentsCreate.vue"
export default component.exports