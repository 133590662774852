var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        { attrs: { "is-loaded": _vm.isLoaded } },
        [
          _vm.canEdit
            ? _c(
                "pga-authorized-content",
                { attrs: { permission: "EditBusinessStartupCustomerOrder" } },
                [_c("pga-edit-button", { on: { click: _vm.Edit } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "pga-skeleton-loader",
        { attrs: { "is-loaded": _vm.isLoaded } },
        [_c("pga-business-startup-customer-order-details")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }