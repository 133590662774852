var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  ' Proposal Evaluation External Project "' +
                    _vm._s(_vm.customerName) +
                    '" - Details '
                ),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4", "align-self": "end" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "ACOM", readonly: "" },
                            model: {
                              value: _vm.acom,
                              callback: function ($$v) {
                                _vm.acom = $$v
                              },
                              expression: "acom",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "Customer Name", readonly: "" },
                            model: {
                              value: _vm.customerName,
                              callback: function ($$v) {
                                _vm.customerName = $$v
                              },
                              expression: "customerName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "8" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "Description", readonly: "" },
                            model: {
                              value: _vm.description,
                              callback: function ($$v) {
                                _vm.description = $$v
                              },
                              expression: "description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "Order Protocol", readonly: "" },
                            model: {
                              value: _vm.orderProtocol,
                              callback: function ($$v) {
                                _vm.orderProtocol = $$v
                              },
                              expression: "orderProtocol",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "Type", readonly: "" },
                            model: {
                              value: _vm.type,
                              callback: function ($$v) {
                                _vm.type = $$v
                              },
                              expression: "type",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-switch", {
                            attrs: {
                              title: "Master Agreement",
                              label: _vm.masterAgreement ? "Yes" : "No",
                              inset: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.masterAgreement,
                              callback: function ($$v) {
                                _vm.masterAgreement = $$v
                              },
                              expression: "masterAgreement",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "CRM Deal Id", readonly: "" },
                            model: {
                              value: _vm.crmDealId,
                              callback: function ($$v) {
                                _vm.crmDealId = $$v
                              },
                              expression: "crmDealId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-currency-field", {
                            attrs: { label: "CRM Amount", readonly: "" },
                            model: {
                              value: _vm.crmAmount,
                              callback: function ($$v) {
                                _vm.crmAmount = $$v
                              },
                              expression: "crmAmount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-date-picker", {
                            attrs: { label: "CRM Last Update", readonly: "" },
                            model: {
                              value: _vm.crmLastUpdate,
                              callback: function ($$v) {
                                _vm.crmLastUpdate = $$v
                              },
                              expression: "crmLastUpdate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("pga-switch", {
                            attrs: {
                              title: "DC Offer Approval",
                              dense: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.approvalOfferDC,
                              callback: function ($$v) {
                                _vm.approvalOfferDC = $$v
                              },
                              expression: "approvalOfferDC",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("pga-switch", {
                            attrs: {
                              title: "DC OfferLimit Approval",
                              dense: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.approvalOfferLimitDC,
                              callback: function ($$v) {
                                _vm.approvalOfferLimitDC = $$v
                              },
                              expression: "approvalOfferLimitDC",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("pga-switch", {
                            attrs: {
                              title: "DG OfferLimit Approval",
                              dense: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.approvalOfferLimitDG,
                              callback: function ($$v) {
                                _vm.approvalOfferLimitDG = $$v
                              },
                              expression: "approvalOfferLimitDG",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("pga-switch", {
                            attrs: {
                              title: "DC MOL Approval",
                              dense: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.approvalMOLDC,
                              callback: function ($$v) {
                                _vm.approvalMOLDC = $$v
                              },
                              expression: "approvalMOLDC",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("pga-switch", {
                            attrs: {
                              title: "DG MOL Approval",
                              dense: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.approvalMOLDG,
                              callback: function ($$v) {
                                _vm.approvalMOLDG = $$v
                              },
                              expression: "approvalMOLDG",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("pga-switch", {
                            attrs: {
                              title: "DC Minium MOL Approval",
                              dense: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.approvalMinimumMOLDC,
                              callback: function ($$v) {
                                _vm.approvalMinimumMOLDC = $$v
                              },
                              expression: "approvalMinimumMOLDC",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("pga-switch", {
                            attrs: {
                              title: "DG Minium MOL Approval",
                              dense: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.approvalMinimumMOLDG,
                              callback: function ($$v) {
                                _vm.approvalMinimumMOLDG = $$v
                              },
                              expression: "approvalMinimumMOLDG",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("pga-switch", {
                            attrs: {
                              title: "SG Review",
                              dense: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.reviewSG,
                              callback: function ($$v) {
                                _vm.reviewSG = $$v
                              },
                              expression: "reviewSG",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("pga-switch", {
                            attrs: {
                              title: "DHRG Approval",
                              dense: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.approvalDHRG,
                              callback: function ($$v) {
                                _vm.approvalDHRG = $$v
                              },
                              expression: "approvalDHRG",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c("v-divider"),
          _c("br"),
          _c(
            "v-expansion-panels",
            {
              attrs: { multiple: "" },
              model: {
                value: _vm.panelsOpened,
                callback: function ($$v) {
                  _vm.panelsOpened = $$v
                },
                expression: "panelsOpened",
              },
            },
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(" Fixed Price Project "),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("pga-offer-evaluation-fixed-price-project-details", {
                        model: {
                          value: _vm.Model.FixedPriceProject,
                          callback: function ($$v) {
                            _vm.$set(_vm.Model, "FixedPriceProject", $$v)
                          },
                          expression: "Model.FixedPriceProject",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v(" Human Resources ")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-card",
                        { attrs: { "elevation-3": "" } },
                        [
                          _c(
                            "pga-offer-evaluation-human-resources-subtotals-details",
                            {
                              model: {
                                value: _vm.Model.HumanResources,
                                callback: function ($$v) {
                                  _vm.$set(_vm.Model, "HumanResources", $$v)
                                },
                                expression: "Model.HumanResources",
                              },
                            }
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mb-3 mt-3" }),
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.Model.HumanResources.Resources,
                          function (item, i) {
                            return _c(
                              "pga-offer-evaluation-human-resources-details",
                              {
                                key: i,
                                model: {
                                  value: _vm.Model.HumanResources.Resources[i],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.Model.HumanResources.Resources,
                                      i,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "Model.HumanResources.Resources[i]",
                                },
                              }
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(" Other Components "),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-card",
                        { attrs: { "elevation-3": "" } },
                        [
                          _c(
                            "pga-offer-evaluation-other-components-subtotals",
                            {
                              model: {
                                value: _vm.Model.OtherComponents,
                                callback: function ($$v) {
                                  _vm.$set(_vm.Model, "OtherComponents", $$v)
                                },
                                expression: "Model.OtherComponents",
                              },
                            }
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mb-3 mt-3" }),
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        [
                          _c(
                            "pga-offer-evaluation-other-components-product-details",
                            {
                              model: {
                                value:
                                  _vm.Model.OtherComponents.ProductsPurchase,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.Model.OtherComponents,
                                    "ProductsPurchase",
                                    $$v
                                  )
                                },
                                expression:
                                  "Model.OtherComponents.ProductsPurchase",
                              },
                            }
                          ),
                          _c(
                            "pga-offer-evaluation-other-components-solution-details",
                            {
                              model: {
                                value:
                                  _vm.Model.OtherComponents.SolutionsPurchase,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.Model.OtherComponents,
                                    "SolutionsPurchase",
                                    $$v
                                  )
                                },
                                expression:
                                  "Model.OtherComponents.SolutionsPurchase",
                              },
                            }
                          ),
                          _c(
                            "pga-offer-evaluation-other-components-service-details",
                            {
                              model: {
                                value:
                                  _vm.Model.OtherComponents.ServicesPurchase,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.Model.OtherComponents,
                                    "ServicesPurchase",
                                    $$v
                                  )
                                },
                                expression:
                                  "Model.OtherComponents.ServicesPurchase",
                              },
                            }
                          ),
                          _c(
                            "pga-offer-evaluation-other-components-transfer-details",
                            {
                              model: {
                                value: _vm.Model.OtherComponents.TransferCosts,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.Model.OtherComponents,
                                    "TransferCosts",
                                    $$v
                                  )
                                },
                                expression:
                                  "Model.OtherComponents.TransferCosts",
                              },
                            }
                          ),
                          _c(
                            "pga-offer-evaluation-other-components-security-details",
                            {
                              model: {
                                value: _vm.Model.OtherComponents.SecurityCosts,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.Model.OtherComponents,
                                    "SecurityCosts",
                                    $$v
                                  )
                                },
                                expression:
                                  "Model.OtherComponents.SecurityCosts",
                              },
                            }
                          ),
                          _vm._l(
                            _vm.Model.OtherComponents.Others,
                            function (item, i) {
                              return _c(
                                "pga-offer-evaluation-other-components-others-details",
                                {
                                  key: i,
                                  model: {
                                    value: _vm.Model.OtherComponents.Others[i],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.Model.OtherComponents.Others,
                                        i,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "Model.OtherComponents.Others[i]",
                                  },
                                }
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(" Additional Costs Presale "),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-card",
                        { attrs: { "elevation-3": "" } },
                        [
                          _c(
                            "pga-offer-evaluation-additional-costs-presale-subtotals-details",
                            {
                              model: {
                                value: _vm.Model.AdditionalCostsPresale,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.Model,
                                    "AdditionalCostsPresale",
                                    $$v
                                  )
                                },
                                expression: "Model.AdditionalCostsPresale",
                              },
                            }
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mb-3 mt-3" }),
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.Model.AdditionalCostsPresale.AdditionalCosts,
                          function (item, i) {
                            return _c(
                              "pga-offer-evaluation-additional-costs-presale-details",
                              {
                                key: i,
                                model: {
                                  value:
                                    _vm.Model.AdditionalCostsPresale
                                      .AdditionalCosts[i],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.Model.AdditionalCostsPresale
                                        .AdditionalCosts,
                                      i,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "Model.AdditionalCostsPresale.AdditionalCosts[i]",
                                },
                              }
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(" Other Costs Presale "),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-card",
                        { attrs: { "elevation-3": "" } },
                        [
                          _c(
                            "pga-offer-evaluation-other-costs-presale-subtotals-details",
                            {
                              model: {
                                value: _vm.Model.OtherCostsPresale,
                                callback: function ($$v) {
                                  _vm.$set(_vm.Model, "OtherCostsPresale", $$v)
                                },
                                expression: "Model.OtherCostsPresale",
                              },
                            }
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mb-3 mt-3" }),
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.Model.OtherCostsPresale.OtherCosts,
                          function (item, i) {
                            return _c(
                              "pga-offer-evaluation-other-costs-presale-details",
                              {
                                key: i,
                                model: {
                                  value:
                                    _vm.Model.OtherCostsPresale.OtherCosts[i],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.Model.OtherCostsPresale.OtherCosts,
                                      i,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "Model.OtherCostsPresale.OtherCosts[i]",
                                },
                              }
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(" Additional Costs Postsale "),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        [
                          _c(
                            "pga-offer-evaluation-additional-costs-postsale-details",
                            {
                              attrs: { tipology: "MAINTENANCE" },
                              model: {
                                value:
                                  _vm.Model.AdditionalCostsPostsale.Maintenance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.Model.AdditionalCostsPostsale,
                                    "Maintenance",
                                    $$v
                                  )
                                },
                                expression:
                                  "Model.AdditionalCostsPostsale.Maintenance",
                              },
                            }
                          ),
                          _c(
                            "pga-offer-evaluation-additional-costs-postsale-details",
                            {
                              attrs: { tipology: "WARRANTY" },
                              model: {
                                value:
                                  _vm.Model.AdditionalCostsPostsale.Warranty,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.Model.AdditionalCostsPostsale,
                                    "Warranty",
                                    $$v
                                  )
                                },
                                expression:
                                  "Model.AdditionalCostsPostsale.Warranty",
                              },
                            }
                          ),
                          _c(
                            "pga-offer-evaluation-additional-costs-postsale-details",
                            {
                              attrs: { tipology: "SUPPORT" },
                              model: {
                                value:
                                  _vm.Model.AdditionalCostsPostsale.Support,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.Model.AdditionalCostsPostsale,
                                    "Support",
                                    $$v
                                  )
                                },
                                expression:
                                  "Model.AdditionalCostsPostsale.Support",
                              },
                            }
                          ),
                          _vm._l(
                            _vm.Model.AdditionalCostsPostsale.Others,
                            function (item, i) {
                              return _c(
                                "pga-offer-evaluation-additional-costs-postsale-details",
                                {
                                  key: i,
                                  attrs: { tipology: "OTHERS" },
                                  model: {
                                    value:
                                      _vm.Model.AdditionalCostsPostsale.Others[
                                        i
                                      ],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.Model.AdditionalCostsPostsale
                                          .Others,
                                        i,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "Model.AdditionalCostsPostsale.Others[i]",
                                  },
                                }
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v(" Attachments ")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("pga-offer-evaluation-attachments-details", {
                        model: {
                          value: _vm.Model.Attachments,
                          callback: function ($$v) {
                            _vm.$set(_vm.Model, "Attachments", $$v)
                          },
                          expression: "Model.Attachments",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { attrs: { vertical: "" } }),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            dark: "",
            clipped: "",
            app: "",
            temporary: !_vm.showMiniVariant && _vm.isMobile,
            permanent: "",
            right: "",
            "mini-variant": _vm.showMiniVariant,
          },
          scopedSlots: _vm._u(
            [
              _vm.isMobile
                ? {
                    key: "append",
                    fn: function () {
                      return [
                        _c("pga-general-button", {
                          attrs: {
                            fab: "",
                            dark: "",
                            right: "",
                            icon: _vm.showMiniVariant
                              ? "mdi-arrow-expand-left"
                              : "mdi-arrow-expand-right",
                          },
                          on: { click: _vm.changeMiniVariant },
                        }),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
          model: {
            value: _vm.isMiniVariant,
            callback: function ($$v) {
              _vm.isMiniVariant = $$v
            },
            expression: "isMiniVariant",
          },
        },
        [
          _vm.showMiniVariant
            ? _c("h2", { staticClass: "vertical-text" }, [_vm._v(" Totals ")])
            : _c(
                "v-container",
                [
                  _c("pga-offer-evaluation-totals-offer-evaluation-details", {
                    attrs: {
                      "totals-offer": _vm.Model.TotalsOfferEvaluation,
                      "fee-total-expected-revenue":
                        _vm.Model.FeeTotalExpectedRevenue,
                    },
                  }),
                  _c(
                    "v-expansion-panels",
                    { attrs: { multiple: "" } },
                    [
                      _c("pga-offer-evaluation-total-amounts-details", {
                        attrs: { "total-amounts": _vm.Model.TotalAmounts },
                      }),
                      _c("pga-offer-evaluation-total-costs-details", {
                        model: {
                          value: _vm.Model,
                          callback: function ($$v) {
                            _vm.Model = $$v
                          },
                          expression: "Model",
                        },
                      }),
                      _c(
                        "pga-offer-evaluation-total-amounts-discounted-details",
                        {
                          attrs: {
                            "total-amounts": _vm.Model.TotalAmounts,
                            "total-amounts-discounted":
                              _vm.Model.TotalAmountsDiscounted,
                          },
                        }
                      ),
                      _c("pga-offer-evaluation-expected-revenue-details", {
                        attrs: {
                          "fee-total-expected-revenue":
                            _vm.Model.FeeTotalExpectedRevenue,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }