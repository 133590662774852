import { render, staticRenderFns } from "./OfferEvaluationPageCustomerOrderCreate.vue?vue&type=template&id=79a88830&"
import script from "./OfferEvaluationPageCustomerOrderCreate.vue?vue&type=script&lang=ts&"
export * from "./OfferEvaluationPageCustomerOrderCreate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79a88830')) {
      api.createRecord('79a88830', component.options)
    } else {
      api.reload('79a88830', component.options)
    }
    module.hot.accept("./OfferEvaluationPageCustomerOrderCreate.vue?vue&type=template&id=79a88830&", function () {
      api.rerender('79a88830', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/feature/Offerte/OfferEvaluation/OfferEvaluationPageCustomerOrderCreate.vue"
export default component.exports