var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    [
      _c("v-expansion-panel-header", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var open = ref.open
              return [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text--secondary", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-fade-transition",
                          { attrs: { "leave-absolute": "" } },
                          [
                            open
                              ? _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "4", md: "3" },
                                      },
                                      [
                                        _c("h3", [
                                          _vm._v(" Human Resource Details "),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-row",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { dense: "" },
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _c("h4", [_vm._v(" Resource Name: ")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(
                                        " " + _vm._s(_vm.resourceName) + " "
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _c("h4", [
                                        _vm._v(" Men Days Expected: "),
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(
                                        " " + _vm._s(_vm.expectedMen || 0) + " "
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _c("h4", [
                                        _vm._v(" Daily Rate Offered: "),
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.FormatMoney(
                                              _vm.dailyPriceOffer || 0
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _c("h4", [_vm._v(" Proposal Amount: ")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.FormatMoney(
                                              _vm.offerAmount || 0
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _c("h4", [_vm._v(" Cost: ")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.FormatMoney(
                                              _vm.expectedCost || 0
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _c("h4", [_vm._v(" Margin: ")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.expectedMarginPerc + "%" || 0
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-expansion-panel-content",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
                [
                  _c("pga-text-field", {
                    attrs: { label: "Business Line", readonly: "" },
                    model: {
                      value: _vm.businessLine,
                      callback: function ($$v) {
                        _vm.businessLine = $$v
                      },
                      expression: "businessLine",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
                [
                  _c("pga-text-field", {
                    attrs: { label: "Tech Area", readonly: "" },
                    model: {
                      value: _vm.techArea,
                      callback: function ($$v) {
                        _vm.techArea = $$v
                      },
                      expression: "techArea",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "8", lg: "6" } },
                [
                  _c("pga-text-field", {
                    attrs: { label: "Supply Tipology", readonly: "" },
                    model: {
                      value: _vm.supplyTipology,
                      callback: function ($$v) {
                        _vm.supplyTipology = $$v
                      },
                      expression: "supplyTipology",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c("pga-text-field", {
                    attrs: { label: "Role", readonly: "" },
                    model: {
                      value: _vm.professionalFigure,
                      callback: function ($$v) {
                        _vm.professionalFigure = $$v
                      },
                      expression: "professionalFigure",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4" } },
                [
                  _c("pga-text-field", {
                    attrs: { label: "Company", readonly: "" },
                    model: {
                      value: _vm.company,
                      callback: function ($$v) {
                        _vm.company = $$v
                      },
                      expression: "company",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4" } },
                [
                  _c("pga-text-field", {
                    attrs: { label: "Resource Name", readonly: "" },
                    model: {
                      value: _vm.resourceName,
                      callback: function ($$v) {
                        _vm.resourceName = $$v
                      },
                      expression: "resourceName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "2" } },
                [
                  _c("pga-number-field", {
                    attrs: { label: "Men Days Expected", readonly: "" },
                    model: {
                      value: _vm.expectedMen,
                      callback: function ($$v) {
                        _vm.expectedMen = $$v
                      },
                      expression: "expectedMen",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "2" } },
                [
                  _c("pga-number-field", {
                    attrs: { label: "Men Days Offered", readonly: "" },
                    model: {
                      value: _vm.offeredMen,
                      callback: function ($$v) {
                        _vm.offeredMen = $$v
                      },
                      expression: "offeredMen",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "2" } },
                [
                  _c("pga-text-field", {
                    attrs: {
                      prefix: "%",
                      label: "Risk Margin %",
                      readonly: "",
                    },
                    model: {
                      value: _vm.riskMargin,
                      callback: function ($$v) {
                        _vm.riskMargin = $$v
                      },
                      expression: "riskMargin",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "2" } },
                [
                  _c("pga-currency-field", {
                    attrs: { label: "Daily Rate Offered", readonly: "" },
                    model: {
                      value: _vm.dailyPriceOffer,
                      callback: function ($$v) {
                        _vm.dailyPriceOffer = $$v
                      },
                      expression: "dailyPriceOffer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "2" } },
                [
                  _c("pga-currency-field", {
                    attrs: { label: "Proposal Amount", readonly: "" },
                    model: {
                      value: _vm.offerAmount,
                      callback: function ($$v) {
                        _vm.offerAmount = $$v
                      },
                      expression: "offerAmount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "2" } },
                [
                  _c("pga-currency-field", {
                    attrs: { label: "Minium Daily Rate", readonly: "" },
                    model: {
                      value: _vm.miniumDailyPrice,
                      callback: function ($$v) {
                        _vm.miniumDailyPrice = $$v
                      },
                      expression: "miniumDailyPrice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "2" } },
                [
                  _c("pga-currency-field", {
                    attrs: { label: "Expected Cost", readonly: "" },
                    model: {
                      value: _vm.expectedCost,
                      callback: function ($$v) {
                        _vm.expectedCost = $$v
                      },
                      expression: "expectedCost",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "2" } },
                [
                  _c("pga-currency-field", {
                    attrs: { label: "Expected Margin", readonly: "" },
                    model: {
                      value: _vm.expectedMargin,
                      callback: function ($$v) {
                        _vm.expectedMargin = $$v
                      },
                      expression: "expectedMargin",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "2" } },
                [
                  _c("pga-text-field", {
                    attrs: {
                      prefix: "%",
                      label: "Expected Margin %",
                      readonly: "",
                    },
                    model: {
                      value: _vm.expectedMarginPerc,
                      callback: function ($$v) {
                        _vm.expectedMarginPerc = $$v
                      },
                      expression: "expectedMarginPerc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }