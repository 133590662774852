var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          !_vm.editRestricted
            ? _c(
                "pga-authorized-content",
                { attrs: { permission: "EditOfferEvaluationCustomerOrder" } },
                [
                  _c("pga-general-button", {
                    attrs: {
                      text: "Save as Draft",
                      icon: "mdi-check-circle",
                      "icon-color": "warning",
                    },
                    on: { click: _vm.SaveAsDraft },
                  }),
                  _c("pga-general-button", {
                    attrs: {
                      text: "Save",
                      icon: "mdi-check-circle",
                      "icon-color": "success",
                    },
                    on: { click: _vm.Save },
                  }),
                ],
                1
              )
            : _c(
                "pga-authorized-content",
                {
                  attrs: {
                    permission: "EditOfferEvaluationCustomerOrderRestricted",
                  },
                },
                [
                  _c("pga-general-button", {
                    attrs: {
                      text: "Save",
                      icon: "mdi-check-circle",
                      "icon-color": "success",
                    },
                    on: { click: _vm.SaveRestricted },
                  }),
                ],
                1
              ),
          _c("pga-cancel-button", { on: { click: _vm.undo } }),
        ],
        1
      ),
      _c(
        "v-form",
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(" Edit Proposal Evaluation for External Project "),
              ]),
              _c(
                "v-card-text",
                [
                  _c("pga-offer-evaluation-header-customer-order-edit", {
                    attrs: { restricted: _vm.editRestricted },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c("v-divider"),
          _c("br"),
          _c(
            "v-expansion-panels",
            {
              attrs: { multiple: "" },
              model: {
                value: _vm.panelsOpened,
                callback: function ($$v) {
                  _vm.panelsOpened = $$v
                },
                expression: "panelsOpened",
              },
            },
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", {
                    attrs: {
                      color:
                        _vm.hasErrorsFixedPrice && !_vm.open ? "error" : "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var open = ref.open
                          return [_vm._v(" Fixed Price Project ")]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("pga-offer-evaluation-fixed-price-project-create", {
                        attrs: { restricted: _vm.editRestricted },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", {
                    attrs: {
                      color:
                        _vm.hasErrorsHumanResources && !_vm.open ? "error" : "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var open = ref.open
                          return [_vm._v(" Human Resources ")]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-card",
                        { attrs: { "elevation-3": "" } },
                        [_c("pga-offer-evaluation-human-resources-subtotals")],
                        1
                      ),
                      _c("v-divider", { staticClass: "mb-3 mt-3" }),
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(_vm.humanResourcesCount, function (comp, i) {
                          return _c(
                            "pga-offer-evaluation-human-resources-create",
                            {
                              key: i,
                              attrs: {
                                index: i,
                                restricted: _vm.editRestricted,
                              },
                            }
                          )
                        }),
                        1
                      ),
                      !_vm.editRestricted
                        ? _c("pga-general-button", {
                            attrs: {
                              icon: "mdi-plus",
                              fab: "",
                              dark: "",
                              bottom: "",
                              right: "",
                              absolute: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addHumanResource()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", {
                    attrs: {
                      color:
                        _vm.hasErrorsOtherComponents && !_vm.open
                          ? "error"
                          : "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var open = ref.open
                          return [_vm._v(" Other Components ")]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-card",
                        { attrs: { "elevation-3": "" } },
                        [_c("pga-offer-evaluation-other-components-subtotals")],
                        1
                      ),
                      _c("v-divider", { staticClass: "mb-3 mt-3" }),
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        [
                          _c(
                            "pga-offer-evaluation-other-components-product-create",
                            { attrs: { restricted: _vm.editRestricted } }
                          ),
                          _c(
                            "pga-offer-evaluation-other-components-solution-create",
                            { attrs: { restricted: _vm.editRestricted } }
                          ),
                          _c(
                            "pga-offer-evaluation-other-components-service-create",
                            { attrs: { restricted: _vm.editRestricted } }
                          ),
                          _c(
                            "pga-offer-evaluation-other-components-transfer-create",
                            { attrs: { restricted: _vm.editRestricted } }
                          ),
                          _c(
                            "pga-offer-evaluation-other-components-security-create",
                            { attrs: { restricted: _vm.editRestricted } }
                          ),
                          _vm._l(_vm.otherComponentsCount, function (comp, i) {
                            return _c(
                              "pga-offer-evaluation-other-components-others-create",
                              {
                                key: i,
                                attrs: {
                                  index: i,
                                  restricted: _vm.editRestricted,
                                },
                              }
                            )
                          }),
                        ],
                        2
                      ),
                      !_vm.editRestricted
                        ? _c("pga-general-button", {
                            attrs: {
                              icon: "mdi-plus",
                              fab: "",
                              dark: "",
                              bottom: "",
                              right: "",
                              absolute: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addOtherComponent()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", {
                    attrs: {
                      color:
                        _vm.hasErrorsAdditionalCostsPresale && !_vm.open
                          ? "error"
                          : "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var open = ref.open
                          return [_vm._v(" Additional Costs Presale ")]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-card",
                        { attrs: { "elevation-3": "" } },
                        [
                          _c(
                            "pga-offer-evaluation-additional-costs-presale-subtotals"
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mb-3 mt-3" }),
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.additionalCostsPresaleCount,
                          function (comp, i) {
                            return _c(
                              "pga-offer-evaluation-additional-costs-presale-create",
                              {
                                key: i,
                                attrs: {
                                  index: i,
                                  restricted: _vm.editRestricted,
                                },
                              }
                            )
                          }
                        ),
                        1
                      ),
                      !_vm.editRestricted
                        ? _c("pga-general-button", {
                            attrs: {
                              icon: "mdi-plus",
                              fab: "",
                              dark: "",
                              bottom: "",
                              right: "",
                              absolute: "",
                            },
                            on: { click: _vm.addAdditionalCostsPresale },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", {
                    attrs: {
                      color:
                        _vm.hasErrorsOtherCostsPresale && !_vm.open
                          ? "error"
                          : "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var open = ref.open
                          return [_vm._v(" Others Costs Presale ")]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-card",
                        { attrs: { "elevation-3": "" } },
                        [
                          _c(
                            "pga-offer-evaluation-other-costs-presale-subtotals"
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mb-3 mt-3" }),
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(_vm.otherCostsPresaleCount, function (comp, i) {
                          return _c(
                            "pga-offer-evaluation-other-costs-presale-create",
                            {
                              key: i,
                              attrs: {
                                index: i,
                                restricted: _vm.editRestricted,
                              },
                            }
                          )
                        }),
                        1
                      ),
                      !_vm.editRestricted
                        ? _c("pga-general-button", {
                            attrs: {
                              icon: "mdi-plus",
                              fab: "",
                              dark: "",
                              bottom: "",
                              right: "",
                              absolute: "",
                            },
                            on: { click: _vm.addOtherCostsPresale },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", {
                    attrs: {
                      color:
                        _vm.hasErrorsAdditionalCostsPostsale && !_vm.open
                          ? "error"
                          : "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var open = ref.open
                          return [_vm._v(" Additional Costs Postsale ")]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        [
                          _c(
                            "pga-offer-evaluation-additional-costs-maintenance-postsale-create",
                            { attrs: { restricted: _vm.editRestricted } }
                          ),
                          _c(
                            "pga-offer-evaluation-additional-costs-warranty-postsale-create",
                            { attrs: { restricted: _vm.editRestricted } }
                          ),
                          _c(
                            "pga-offer-evaluation-additional-costs-support-postsale-create",
                            { attrs: { restricted: _vm.editRestricted } }
                          ),
                          _vm._l(
                            _vm.otherCostsPostsaleCount,
                            function (comp, i) {
                              return _c(
                                "pga-offer-evaluation-additional-costs-others-postsale-create",
                                {
                                  key: i,
                                  attrs: {
                                    index: i,
                                    restricted: _vm.editRestricted,
                                  },
                                }
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      !_vm.editRestricted
                        ? _c("pga-general-button", {
                            attrs: {
                              icon: "mdi-plus",
                              fab: "",
                              dark: "",
                              bottom: "",
                              right: "",
                              absolute: "",
                            },
                            on: { click: _vm.addOtherCostsPostsale },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", {
                    attrs: {
                      color:
                        _vm.hasErrorsAttachments && !_vm.open ? "error" : "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var open = ref.open
                          return [_vm._v(" Attachments ")]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("pga-offer-evaluation-attachments-create", {
                        attrs: { restricted: _vm.editRestricted },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c("br"),
          _c("br"),
        ],
        1
      ),
      _c("v-divider", { attrs: { vertical: "" } }),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            dark: "",
            clipped: "",
            app: "",
            temporary: !_vm.showMiniVariant && _vm.isMobile,
            permanent: "",
            right: "",
            "mini-variant": _vm.showMiniVariant,
          },
          scopedSlots: _vm._u(
            [
              _vm.isMobile
                ? {
                    key: "append",
                    fn: function () {
                      return [
                        _c("pga-general-button", {
                          attrs: {
                            fab: "",
                            dark: "",
                            right: "",
                            icon: _vm.showMiniVariant
                              ? "mdi-arrow-expand-left"
                              : "mdi-arrow-expand-right",
                          },
                          on: { click: _vm.changeMiniVariant },
                        }),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
          model: {
            value: _vm.isMiniVariant,
            callback: function ($$v) {
              _vm.isMiniVariant = $$v
            },
            expression: "isMiniVariant",
          },
        },
        [
          _vm.showMiniVariant
            ? _c("h2", { staticClass: "vertical-text" }, [_vm._v(" Totals ")])
            : _c(
                "v-container",
                [
                  _c("pga-offer-evaluation-totals-offer-evaluation-create"),
                  _c(
                    "v-expansion-panels",
                    { attrs: { multiple: "" } },
                    [
                      _c("pga-offer-evaluation-total-amounts-create"),
                      _c("pga-offer-evaluation-total-costs-create"),
                      _c(
                        "pga-offer-evaluation-total-amounts-discounted-create",
                        { attrs: { restricted: _vm.editRestricted } }
                      ),
                      _c("pga-offer-evaluation-expected-revenue-create", {
                        attrs: { restricted: _vm.editRestricted },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }