var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("pga-text-field", {
                attrs: { label: "ACOM", readonly: "" },
                model: {
                  value: _vm.acom,
                  callback: function ($$v) {
                    _vm.acom = $$v
                  },
                  expression: "acom",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("pga-text-field", {
                attrs: { label: "Customer", readonly: "" },
                model: {
                  value: _vm.customer,
                  callback: function ($$v) {
                    _vm.customer = $$v
                  },
                  expression: "customer",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("pga-dropdown-search", {
                attrs: {
                  items: _vm.customersDropdown,
                  label: "Final Customer",
                  "show-menu": _vm.showSecondDropdown,
                  loading: _vm.dropdownLoading,
                  "key-error": "FinalCustomer",
                  errors: _vm.Errors,
                },
                on: {
                  click: function ($event) {
                    _vm.showSecondDropdown = !_vm.showSecondDropdown
                  },
                  "search-input": _vm.setFinalCustomerManually,
                },
                model: {
                  value: _vm.finalCustomer,
                  callback: function ($$v) {
                    _vm.finalCustomer = $$v
                  },
                  expression: "finalCustomer",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("pga-text-field", {
                attrs: {
                  label: "Description",
                  "key-error": "Description",
                  errors: _vm.Errors,
                },
                model: {
                  value: _vm.description,
                  callback: function ($$v) {
                    _vm.description = $$v
                  },
                  expression: "description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "2" } },
            [
              _c("pga-switch", {
                attrs: { title: "Advance Supply", inset: "" },
                model: {
                  value: _vm.advanceSupply,
                  callback: function ($$v) {
                    _vm.advanceSupply = $$v
                  },
                  expression: "advanceSupply",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-date-picker", {
                attrs: {
                  label: "Start Date",
                  "key-error": "StartDate",
                  errors: _vm.Errors,
                },
                model: {
                  value: _vm.startDate,
                  callback: function ($$v) {
                    _vm.startDate = $$v
                  },
                  expression: "startDate",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-date-picker", {
                attrs: {
                  label: "Expected End Date",
                  "key-error": "ExpectedEndDate",
                  errors: _vm.Errors,
                },
                model: {
                  value: _vm.expectedEndDate,
                  callback: function ($$v) {
                    _vm.expectedEndDate = $$v
                  },
                  expression: "expectedEndDate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-select", {
                attrs: {
                  label: "Place",
                  "item-value": "Value",
                  "item-text": "Name",
                  items: _vm.placeDropdown,
                },
                model: {
                  value: _vm.place,
                  callback: function ($$v) {
                    _vm.place = $$v
                  },
                  expression: "place",
                },
              }),
              _c("pga-text-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.place === 2,
                    expression: "place === 2",
                  },
                ],
                attrs: {
                  label: "Address",
                  "key-error": "Address",
                  errors: _vm.Errors,
                },
                model: {
                  value: _vm.address,
                  callback: function ($$v) {
                    _vm.address = $$v
                  },
                  expression: "address",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("pga-text-field", {
                attrs: {
                  label: "Customer Contract Manager",
                  "key-error": "CustomerContractManager",
                  errors: _vm.Errors,
                },
                model: {
                  value: _vm.customerContractManager,
                  callback: function ($$v) {
                    _vm.customerContractManager = $$v
                  },
                  expression: "customerContractManager",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            _vm._l(_vm.otherCustomerReferralsCount, function (_, i) {
              return _c("pga-other-customer-referral-edit", {
                key: i,
                attrs: { index: i },
              })
            }),
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "2" } },
            [
              _c("pga-general-button", {
                attrs: {
                  absolute: "",
                  dark: "",
                  "x-small": "",
                  fab: "",
                  icon: "mdi-plus",
                },
                on: { click: _vm.addOtherCustomerReferral },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
            [
              _c("pga-text-field", {
                attrs: { label: "Business Lines", readonly: "" },
                model: {
                  value: _vm.businessLine,
                  callback: function ($$v) {
                    _vm.businessLine = $$v
                  },
                  expression: "businessLine",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
            [
              _c("pga-text-field", {
                attrs: { label: "Tech Area", readonly: "" },
                model: {
                  value: _vm.techArea,
                  callback: function ($$v) {
                    _vm.techArea = $$v
                  },
                  expression: "techArea",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8", lg: "6" } },
            [
              _c("pga-text-field", {
                attrs: { label: "Supply Tipology", readonly: "" },
                model: {
                  value: _vm.supplyTipology,
                  callback: function ($$v) {
                    _vm.supplyTipology = $$v
                  },
                  expression: "supplyTipology",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "2" } },
            [
              _c("pga-text-field", {
                attrs: { label: "Cost Center", readonly: "" },
                model: {
                  value: _vm.areaManager,
                  callback: function ($$v) {
                    _vm.areaManager = $$v
                  },
                  expression: "areaManager",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8" } },
            _vm._l(_vm.deliverableCount, function (_, i) {
              return _c("pga-deliverable-edit", { key: i, attrs: { index: i } })
            }),
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "2" } },
            [
              _c("pga-general-button", {
                attrs: {
                  absolute: "",
                  dark: "",
                  "x-small": "",
                  fab: "",
                  icon: "mdi-plus",
                },
                on: { click: _vm.addDeliverable },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "2" } },
            [
              _c("pga-switch", {
                attrs: { title: "Privacy", inset: "" },
                model: {
                  value: _vm.privacy,
                  callback: function ($$v) {
                    _vm.privacy = $$v
                  },
                  expression: "privacy",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "10" } },
            [
              _c("pga-text-area", {
                attrs: {
                  label: "Notes",
                  "key-error": "Notes",
                  errors: _vm.Errors,
                },
                model: {
                  value: _vm.notes,
                  callback: function ($$v) {
                    _vm.notes = $$v
                  },
                  expression: "notes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }