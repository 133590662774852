var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    [
      _c("v-expansion-panel-header", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var open = ref.open
              return [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text--secondary", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-fade-transition",
                          { attrs: { "leave-absolute": "" } },
                          [
                            open
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          absolute: "",
                                          dark: "",
                                          fab: "",
                                          center: "",
                                          right: "",
                                          color: "pink",
                                        },
                                        on: {
                                          click:
                                            _vm.removePgaValutazioneOffertaAggiuntiviPresaleCreate,
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-minus")])],
                                      1
                                    ),
                                    _c("span", [
                                      _vm._v("Dettaglio Costi Aggiuntivi"),
                                    ]),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-row",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { "no-gutters": "" },
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        "Nome Risorsa: " +
                                          _vm._s(
                                            _vm.NomeRisorsaPresale ||
                                              "Non impostato"
                                          )
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        "GG/Uomo: " +
                                          _vm._s(
                                            _vm.GGUomoOffPresale ||
                                              "Non impostato"
                                          )
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        "Costo Atteso: " +
                                          _vm._s(
                                            _vm.CostoAttesoPresale + "€" ||
                                              "Non impostato"
                                          )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-expansion-panel-content",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "2" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.BusinessLinePresaleItems,
                      label: "Business Line",
                      required: "",
                      rules: [
                        function (v) {
                          return !!v || "Item is required"
                        },
                      ],
                    },
                    model: {
                      value: _vm.BusinessLinePresale,
                      callback: function ($$v) {
                        _vm.BusinessLinePresale = $$v
                      },
                      expression: "BusinessLinePresale",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "2" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.TechAreaPresaleItems,
                      label: "Tech Area",
                      required: "",
                      rules: [
                        function (v) {
                          return !!v || "Item is required"
                        },
                      ],
                    },
                    model: {
                      value: _vm.TechAreaPresale,
                      callback: function ($$v) {
                        _vm.TechAreaPresale = $$v
                      },
                      expression: "TechAreaPresale",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "2" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.TipologiaFornituraPresaleItems,
                      label: "Tipologia Fornitura",
                      required: "",
                      rules: [
                        function (v) {
                          return !!v || "Item is required"
                        },
                      ],
                    },
                    model: {
                      value: _vm.TipologiaFornituraPresale,
                      callback: function ($$v) {
                        _vm.TipologiaFornituraPresale = $$v
                      },
                      expression: "TipologiaFornituraPresale",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "2" } },
                [
                  _c("v-text-field", {
                    attrs: { label: "Figura Professionale" },
                    model: {
                      value: _vm.FiguraProfessionalePresale,
                      callback: function ($$v) {
                        _vm.FiguraProfessionalePresale = $$v
                      },
                      expression: "FiguraProfessionalePresale",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "2" } },
                [
                  _c("v-text-field", {
                    attrs: { label: "Societa'" },
                    model: {
                      value: _vm.SocietaPresale,
                      callback: function ($$v) {
                        _vm.SocietaPresale = $$v
                      },
                      expression: "SocietaPresale",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "2" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.ItemsNomeRisorsaPresale,
                      label: "Nome Risorsa",
                      required: "",
                      rules: [
                        function (v) {
                          return !!v || "Item is required"
                        },
                      ],
                    },
                    model: {
                      value: _vm.NomeRisorsaPresale,
                      callback: function ($$v) {
                        _vm.NomeRisorsaPresale = $$v
                      },
                      expression: "NomeRisorsaPresale",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "2" } },
                [
                  _c("v-text-field", {
                    attrs: { label: "GG/Uomo Offerta", type: "number" },
                    model: {
                      value: _vm.GGUomoOffPresale,
                      callback: function ($$v) {
                        _vm.GGUomoOffPresale = $$v
                      },
                      expression: "GGUomoOffPresale",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      prefix: "€",
                      label: "Costo Giornaliero",
                      type: "number",
                    },
                    model: {
                      value: _vm.CostoGiornalieroPresale,
                      callback: function ($$v) {
                        _vm.CostoGiornalieroPresale = $$v
                      },
                      expression: "CostoGiornalieroPresale",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4", md: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      prefix: "€",
                      label: "Costo Atteso",
                      type: "number",
                      readonly: "",
                    },
                    model: {
                      value: _vm.CostoAttesoPresale,
                      callback: function ($$v) {
                        _vm.CostoAttesoPresale = $$v
                      },
                      expression: "CostoAttesoPresale",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }