var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.internalProjectDetails.Versions.length === 0
        ? _c(
            "pga-bottom-navigation",
            { attrs: { "is-loaded": _vm.isLoaded } },
            [
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditOfferEvaluationInternalProject" } },
                [
                  _vm.isEditable
                    ? _c("pga-edit-button", { on: { click: _vm.Edit } })
                    : _vm._e(),
                  _vm.isActivable
                    ? _c("pga-general-button", {
                        attrs: { icon: "mdi-auto-upload", text: "Submit" },
                        on: { click: _vm.Active },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditOfferEvaluationInternalProject" } },
                [
                  _vm.isApprovalRequestable
                    ? _c("pga-general-button", {
                        attrs: {
                          icon: "mdi-map-marker-question",
                          text: "Request Approval",
                        },
                        on: { click: _vm.RequestApproval },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("pga-authorized-only-one-content", {
                attrs: {
                  permissions: [
                    "OfferEvaluationInternalProjectApproveProjectDG",
                    "OfferEvaluationInternalProjectReviewSG",
                  ],
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "OfferEvaluationInternalProjectApproveProjectDG",
                      fn: function () {
                        return [
                          _vm.isApprovableByDG
                            ? _c("pga-approve-button", {
                                on: { click: _vm.Approve },
                              })
                            : _vm._e(),
                          _vm.isApprovableByDG
                            ? _c("pga-request-changes-button", {
                                on: { click: _vm.RequestChanges },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "OfferEvaluationInternalProjectReviewSG",
                      fn: function () {
                        return [
                          _vm.isApprovableBySG
                            ? _c("pga-approve-button", {
                                on: { click: _vm.Approve },
                              })
                            : _vm._e(),
                          _vm.isApprovableBySG
                            ? _c("pga-request-changes-button", {
                                on: { click: _vm.RequestChanges },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2241819134
                ),
              }),
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditOfferEvaluationInternalProject" } },
                [
                  _vm.canCreateAssessment
                    ? _c("pga-edit-button", {
                        attrs: { text: "Change" },
                        on: { click: _vm.CreateAssessment },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditOfferEvaluationInternalProject" } },
                [
                  _vm.isDeletable
                    ? _c("pga-delete-button", {
                        on: {
                          click: function ($event) {
                            _vm.showDialog = true
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "pga-bottom-navigation",
            { attrs: { "is-loaded": _vm.isLoaded } },
            [
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditOfferEvaluationInternalProject" } },
                [
                  _vm.isEditable
                    ? _c("pga-edit-button", { on: { click: _vm.Edit } })
                    : _vm._e(),
                  _vm.isNotificableAssessment
                    ? _c("pga-general-button", {
                        attrs: {
                          icon: "mdi-auto-upload",
                          text: "Notify Assessment",
                        },
                        on: { click: _vm.NotifyAssessment },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditOfferEvaluationInternalProject" } },
                [
                  _vm.isApprovalRequestableAssessment
                    ? _c("pga-general-button", {
                        attrs: {
                          icon: "mdi-map-marker-question",
                          text: "Request Approval Assessment",
                        },
                        on: { click: _vm.RequestApprovalAssessment },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("pga-authorized-only-one-content", {
                attrs: {
                  permissions: [
                    "OfferEvaluationInternalProjectApproveProjectDG",
                    "OfferEvaluationInternalProjectReviewSG",
                  ],
                },
                scopedSlots: _vm._u([
                  {
                    key: "OfferEvaluationInternalProjectApproveProjectDG",
                    fn: function () {
                      return [
                        _vm.isApprovableAssessmentByDG
                          ? _c("pga-approve-button", {
                              on: { click: _vm.ApproveAssessment },
                            })
                          : _vm._e(),
                        _vm.isApprovableAssessmentByDG
                          ? _c("pga-request-changes-button", {
                              on: { click: _vm.RequestChanges },
                            })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "OfferEvaluationInternalProjectReviewSG",
                    fn: function () {
                      return [
                        _vm.isApprovableAssessmentBySG
                          ? _c("pga-approve-button", {
                              on: { click: _vm.ApproveAssessment },
                            })
                          : _vm._e(),
                        _vm.isApprovableAssessmentBySG
                          ? _c("pga-request-changes-button", {
                              on: { click: _vm.RequestChanges },
                            })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditOfferEvaluationInternalProject" } },
                [
                  _vm.canCreateAssessment
                    ? _c("pga-edit-button", {
                        attrs: { text: "Create Revision" },
                        on: { click: _vm.CreateAssessment },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditOfferEvaluationInternalProject" } },
                [
                  _vm.isDeletable
                    ? _c("pga-delete-button", {
                        on: {
                          click: function ($event) {
                            _vm.showDialog = true
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "pga-skeleton-loader",
        { attrs: { "is-loaded": _vm.isLoaded } },
        [
          _vm.internalProjectDetails.Versions.length === 0
            ? _c("pga-offer-evaluation-internal-project-details", {
                model: {
                  value: _vm.internalProjectDetails,
                  callback: function ($$v) {
                    _vm.internalProjectDetails = $$v
                  },
                  expression: "internalProjectDetails",
                },
              })
            : _c(
                "v-tabs",
                { attrs: { "background-color": "transparent", grow: "" } },
                [
                  _c("v-tab", [_vm._v(" Current ")]),
                  _c(
                    "v-tab-item",
                    [
                      _c("pga-offer-evaluation-internal-project-details", {
                        model: {
                          value: _vm.internalProjectDetails,
                          callback: function ($$v) {
                            _vm.internalProjectDetails = $$v
                          },
                          expression: "internalProjectDetails",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(
                    _vm.internalProjectDetails.Versions,
                    function (ver, i) {
                      return _c("v-tab", { key: i }, [
                        _vm._v(
                          " Version " +
                            _vm._s(ver.Version) +
                            " - " +
                            _vm._s(_vm.formatDate(new Date(ver.LastModified))) +
                            " "
                        ),
                      ])
                    }
                  ),
                  _vm._l(
                    _vm.internalProjectDetails.Versions,
                    function (ver, i) {
                      return _c(
                        "v-tab-item",
                        { key: i },
                        [
                          _c("pga-offer-evaluation-internal-project-details", {
                            model: {
                              value: ver.InternalProject,
                              callback: function ($$v) {
                                _vm.$set(ver, "InternalProject", $$v)
                              },
                              expression: "ver.InternalProject",
                            },
                          }),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
          _c("pga-dialog", {
            attrs: {
              title: "Deleting Internal Project",
              "icon-title": "mdi-delete-alert",
              message:
                "Are you sure you want to delete the Internal Project '" +
                _vm.internalProjectDetails.Title +
                "'?",
              color: "orange",
              "icon-button": "mdi-checkbox-marked-circle-outline",
            },
            on: {
              agree: _vm.Delete,
              reject: function ($event) {
                _vm.showDialog = false
              },
            },
            model: {
              value: _vm.showDialog,
              callback: function ($$v) {
                _vm.showDialog = $$v
              },
              expression: "showDialog",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }