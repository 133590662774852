var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v("Subtotali Costi Aggiuntivi Presale"),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("v-text-field", {
                attrs: { label: "GG/Uomo Offerta", readonly: "" },
                model: {
                  value: _vm.subGGUomoOffPresale,
                  callback: function ($$v) {
                    _vm.subGGUomoOffPresale = $$v
                  },
                  expression: "subGGUomoOffPresale",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("v-text-field", {
                attrs: { label: "Costo Atteso", readonly: "", prefix: "€" },
                model: {
                  value: _vm.subCostoAttesoPresale,
                  callback: function ($$v) {
                    _vm.subCostoAttesoPresale = $$v
                  },
                  expression: "subCostoAttesoPresale",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }