var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    [
      _c("v-expansion-panel-header", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var open = ref.open
              return [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text--secondary", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-fade-transition",
                          { attrs: { "leave-absolute": "" } },
                          [
                            open
                              ? _c(
                                  "div",
                                  [
                                    _c("span", [_vm._v("Dettaglio risorsa")]),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          dark: "",
                                          fab: "",
                                          center: "",
                                          right: "",
                                          color: "pink",
                                        },
                                        on: { click: _vm.removeRisorsa },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-minus")])],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          dark: "",
                                          fab: "",
                                          center: "",
                                          right: "",
                                          color: "pink",
                                        },
                                        on: { click: _vm.duplicateRisorsa },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-animation")])],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-row",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { "no-gutters": "" },
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        "Nome Risorsa: " +
                                          _vm._s(
                                            _vm.NomeRisorsa || "Non impostato"
                                          )
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        "GG/Uomo: " +
                                          _vm._s(
                                            _vm.GGUomoPrev || "Non impostato"
                                          )
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        "Tariffa Offerta: " +
                                          _vm._s(
                                            _vm.TariffaOfferta + "€" ||
                                              "Non impostato"
                                          )
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        "Importo Offerta: " +
                                          _vm._s(
                                            _vm.ImportoOfferta + "€" ||
                                              "Non impostato"
                                          )
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        "Costo: " +
                                          _vm._s(
                                            _vm.CostoAtteso + "€" ||
                                              "Non impostato"
                                          )
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        "Margine: " +
                                          _vm._s(
                                            _vm.MargineAttesoPerc + "%" ||
                                              "Non impostato"
                                          )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-expansion-panel-content",
        [
          _c(
            "v-form",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "2" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.BusinessLineItems,
                          label: "Business Line",
                          required: "",
                          rules: [
                            function (v) {
                              return !!v || "Campo obbligatorio"
                            },
                          ],
                        },
                        model: {
                          value: _vm.BusinessLine,
                          callback: function ($$v) {
                            _vm.BusinessLine = $$v
                          },
                          expression: "BusinessLine",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "2" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.TechAreaItems,
                          label: "Tech Area",
                          required: "",
                          rules: [
                            function (v) {
                              return !!v || "Campo obbligatorio"
                            },
                          ],
                        },
                        model: {
                          value: _vm.TechArea,
                          callback: function ($$v) {
                            _vm.TechArea = $$v
                          },
                          expression: "TechArea",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "2" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.TipologiaFornituraItems,
                          label: "Tipologia Fornitura",
                          required: "",
                          rules: [
                            function (v) {
                              return !!v || "Campo obbligatorio"
                            },
                          ],
                        },
                        model: {
                          value: _vm.TipologiaFornitura,
                          callback: function ($$v) {
                            _vm.TipologiaFornitura = $$v
                          },
                          expression: "TipologiaFornitura",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Figura Professionale",
                          rules: [
                            function (v) {
                              return !!v || "Campo obbligatorio"
                            },
                          ],
                        },
                        model: {
                          value: _vm.FiguraProfessionale,
                          callback: function ($$v) {
                            _vm.FiguraProfessionale = $$v
                          },
                          expression: "FiguraProfessionale",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Societa'",
                          rules: [
                            function (v) {
                              return !!v && _vm.ImportoOfferta <= 0
                            } || "Campo obbligatorio",
                          ],
                        },
                        model: {
                          value: _vm.Societa,
                          callback: function ($$v) {
                            _vm.Societa = $$v
                          },
                          expression: "Societa",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "2" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.ItemsNomeRisorsa,
                          label: "Nome Risorsa",
                          required: "",
                          rules: [
                            function (v) {
                              return !!v || "Campo obbligatorio"
                            },
                          ],
                        },
                        model: {
                          value: _vm.NomeRisorsa,
                          callback: function ($$v) {
                            _vm.NomeRisorsa = $$v
                          },
                          expression: "NomeRisorsa",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "GG/Uomo Previsti", type: "number" },
                        model: {
                          value: _vm.GGUomoPrev,
                          callback: function ($$v) {
                            _vm.GGUomoPrev = $$v
                          },
                          expression: "GGUomoPrev",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          "hide-details": "",
                          rules: [
                            function (v) {
                              return !!v || "Campo obbligatorio"
                            },
                          ],
                        },
                        model: {
                          value: _vm.GGUomoOff,
                          callback: function ($$v) {
                            _vm.GGUomoOff = $$v
                          },
                          expression: "GGUomoOff",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          prefix: "%",
                          label: "Margine di Rischio %",
                          type: "number",
                          readonly: "",
                        },
                        model: {
                          value: _vm.MargineRischio,
                          callback: function ($$v) {
                            _vm.MargineRischio = $$v
                          },
                          expression: "MargineRischio",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          prefix: "€",
                          label: "Tariffa Giornaliera Offerta",
                          type: "number",
                          rules: [
                            function (v) {
                              return !!v || "Campo obbligatorio"
                            },
                          ],
                        },
                        model: {
                          value: _vm.TariffaOfferta,
                          callback: function ($$v) {
                            _vm.TariffaOfferta = $$v
                          },
                          expression: "TariffaOfferta",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          prefix: "€",
                          label: "Importo Offerta",
                          type: "number",
                          readonly: "",
                        },
                        model: {
                          value: _vm.ImportoOfferta,
                          callback: function ($$v) {
                            _vm.ImportoOfferta = $$v
                          },
                          expression: "ImportoOfferta",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          prefix: "€",
                          label: "Tariffa Giornaliera Minima",
                          type: "number",
                          rules: [
                            function (v) {
                              return !!v || "Campo obbligatorio"
                            },
                          ],
                        },
                        model: {
                          value: _vm.TariffaMinima,
                          callback: function ($$v) {
                            _vm.TariffaMinima = $$v
                          },
                          expression: "TariffaMinima",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          prefix: "€",
                          label: "Costo Atteso",
                          type: "number",
                          readonly: "",
                        },
                        model: {
                          value: _vm.CostoAtteso,
                          callback: function ($$v) {
                            _vm.CostoAtteso = $$v
                          },
                          expression: "CostoAtteso",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Margine Atteso",
                          type: "number",
                          readonly: "",
                        },
                        model: {
                          value: _vm.MargineAtteso,
                          callback: function ($$v) {
                            _vm.MargineAtteso = $$v
                          },
                          expression: "MargineAtteso",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          prefix: "%",
                          label: "Margine Atteso %",
                          type: "number",
                          readonly: "",
                        },
                        model: {
                          value: _vm.MargineAttesoPerc,
                          callback: function ($$v) {
                            _vm.MargineAttesoPerc = $$v
                          },
                          expression: "MargineAttesoPerc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4", md: "2" } },
                    [
                      _c("pga-number-field", {
                        attrs: {
                          "pga-number-field-value": _vm.GGUomoOff,
                          "nome-field": "GG/Uomo Offerta test",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.PgaNumberField,
                          callback: function ($$v) {
                            _vm.PgaNumberField = $$v
                          },
                          expression: "PgaNumberField",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4", md: "2" } },
                    [
                      _c("pga-currency-field", {
                        attrs: {
                          "pga-currency-field-value": _vm.TariffaOfferta,
                          "nome-field": "Tariffa Offerta test",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.PgaCurrencyField,
                          callback: function ($$v) {
                            _vm.PgaCurrencyField = $$v
                          },
                          expression: "PgaCurrencyField",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }