var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8" } },
            [
              _c("pga-text-field", {
                attrs: {
                  label: "Project Title",
                  readonly: _vm.readonly,
                  "key-error": "Title",
                  errors: _vm.Errors,
                },
                model: {
                  value: _vm.title,
                  callback: function ($$v) {
                    _vm.title = $$v
                  },
                  expression: "title",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("pga-date-picker", {
                attrs: {
                  label: "Date",
                  readonly: _vm.readonly,
                  "key-error": "Date",
                  errors: _vm.Errors,
                },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
            [
              _c("pga-select", {
                attrs: {
                  items: _vm.businessLineDropdown,
                  label: "Business Lines",
                  "item-value": "Id",
                  "item-text": "Title",
                  clearable: "",
                  "key-error": "BusinessLineId",
                  errors: _vm.Errors,
                },
                model: {
                  value: _vm.businessLine,
                  callback: function ($$v) {
                    _vm.businessLine = $$v
                  },
                  expression: "businessLine",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4", lg: "3" } },
            [
              _c("pga-select", {
                attrs: {
                  items: _vm.techAreaDropdown,
                  label: "Tech Area",
                  "item-value": "Id",
                  "item-text": "Name",
                  clearable: "",
                  "key-error": "TechAreaId",
                  errors: _vm.Errors,
                },
                model: {
                  value: _vm.techArea,
                  callback: function ($$v) {
                    _vm.techArea = $$v
                  },
                  expression: "techArea",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8", lg: "6" } },
            [
              _c("pga-select", {
                attrs: {
                  items: _vm.supplyTipologyDropdown,
                  label: "Supply Tipology",
                  "item-value": "Id",
                  "item-text": "Name",
                  clearable: "",
                  "key-error": "SupplyTipologyId",
                  errors: _vm.Errors,
                },
                model: {
                  value: _vm.supplyTipology,
                  callback: function ($$v) {
                    _vm.supplyTipology = $$v
                  },
                  expression: "supplyTipology",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }