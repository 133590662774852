var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    [
      _c("v-expansion-panel-header", {
        attrs: { color: _vm.errorSubSection },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var open = ref.open
              return [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text--secondary", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-fade-transition",
                          { attrs: { "leave-absolute": "" } },
                          [
                            open
                              ? _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    !_vm.restricted
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "1" } },
                                          [
                                            _c("pga-general-button", {
                                              attrs: {
                                                absolute: "",
                                                dark: "",
                                                small: "",
                                                fab: "",
                                                icon: "mdi-minus",
                                              },
                                              on: { click: _vm.removeElement },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "4", md: "3" },
                                      },
                                      [
                                        _c("h3", [
                                          _vm._v(
                                            " Other Costs Presale Details "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-row",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { dense: "" },
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "4", md: "3" },
                                      },
                                      [
                                        _c("h4", [_vm._v(" Expected Cost: ")]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.FormatMoney(
                                                _vm.expectedCost || 0
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-expansion-panel-content",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              !_vm.restricted
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "5" } },
                    [
                      _c("pga-dropdown-search", {
                        attrs: {
                          items: _vm.suppliersDropdown,
                          "item-text": "BusinessName",
                          label: "Supplier",
                          "show-menu": _vm.showDynamicDropdown,
                          "return-object": "",
                          loading: _vm.dropdownLoading,
                        },
                        on: {
                          click: function ($event) {
                            _vm.showDynamicDropdown = !_vm.showDynamicDropdown
                          },
                          "search-input": _vm.requestSuppliersDebounced,
                        },
                        model: {
                          value: _vm.supplier,
                          callback: function ($$v) {
                            _vm.supplier = $$v
                          },
                          expression: "supplier",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "3" } },
                [
                  _c("pga-text-field", {
                    attrs: {
                      label: "Supplier Code",
                      "key-error": "OtherCosts[" + _vm.index + "].SupplierCode",
                      errors: _vm.Errors,
                      readonly: _vm.restricted,
                    },
                    model: {
                      value: _vm.supplierCode,
                      callback: function ($$v) {
                        _vm.supplierCode = $$v
                      },
                      expression: "supplierCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c("pga-text-field", {
                    attrs: {
                      label: "Supplier Business Name",
                      "key-error":
                        "OtherCosts[" + _vm.index + "].SupplierBusinessName",
                      errors: _vm.Errors,
                      readonly: _vm.restricted,
                    },
                    model: {
                      value: _vm.supplierBusinessName,
                      callback: function ($$v) {
                        _vm.supplierBusinessName = $$v
                      },
                      expression: "supplierBusinessName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c("pga-text-field", {
                    attrs: {
                      label: "Description",
                      "key-error": "OtherCosts[" + _vm.index + "].Description",
                      errors: _vm.Errors,
                      readonly: _vm.restricted,
                    },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "3" } },
                [
                  _c("pga-currency-field", {
                    attrs: {
                      label: "Expected Cost",
                      "key-error": "OtherCosts[" + _vm.index + "].ExpectedCost",
                      errors: _vm.Errors,
                      readonly: _vm.restricted,
                    },
                    model: {
                      value: _vm.expectedCost,
                      callback: function ($$v) {
                        _vm.expectedCost = $$v
                      },
                      expression: "expectedCost",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }